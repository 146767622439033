import PdfPayment from "./PdfPayment";
import { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";

type PdfModalProps = {
  isOpen: boolean;
  onClose: () => void;
  bookId: string;
  bookName: string;
  price: number;
};

const PdfModal = ({
  isOpen,
  onClose,
  bookId,
  bookName,
  price,
}: PdfModalProps) => {
  const [isBought, setIsBought] = useState("false");

  useEffect(() => {
    // check if the user already bought the book - fetch from server
    const isPdfBought = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ADDRESS}/book/${bookId}/pdf`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
        }
      );

      if (response.status !== 200) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      const res = await response.json();
      setIsBought(res.isBought);
    };

    isPdfBought();
  }, [bookId]);

  if (!isOpen) {
    return null;
  }
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="w-full max-w-lg">
        <div className="flex items-center justify-center p-4 px-6">
          <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex flex-col items-center justify-center">
                <h3 className="mt-4 text-xl font-semibold leading-6 text-gray-900">
                  Get your PDF now
                </h3>
                {isBought === "true" ? (
                  <div>
                    <a
                      href={`https://d2h7guss2h2lht.cloudfront.net/${bookId}/${bookName.replaceAll(
                        " ",
                        "_"
                      )}.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-blue-500 hover:bg-blue-700 text-md text-white mt-2 py-1 px-2 rounded inline-flex items-center"
                    >
                      download pdf
                    </a>
                  </div>
                ) : (
                  <div className="mt-2 flex flex-col items-center">
                    <div className="flex items-center">
                      <p className="m-2 text-md text-gray-500">
                        Buy it for {price}$
                      </p>
                      <div className="flex mt-1">
                        <PdfPayment
                          product_id="pdf"
                          bookId={bookId}
                          bookName={bookName}
                          onClose={onClose}
                        />
                      </div>
                    </div>
                    <div className="flex items-center">
                      <p className="m-2 text-md text-gray-500">
                        Or to get it free & watermarked
                      </p>
                      <a
                        href={`https://d2h7guss2h2lht.cloudfront.net/${bookId}/${bookName.replaceAll(
                          " ",
                          "_"
                        )}_free.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-blue-500 hover:bg-blue-700 text-md text-white py-1 px-2 rounded"
                      >
                        click here
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center justify-center mt-2">
                <MdClose
                  onClick={onClose}
                  className="text-blue-500 text-bold text-2xl cursor-pointer flex justify-center items-center hover:text-blue-700"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfModal;
