import ReactGA from 'react-ga4';

const userAuth = () => {
    ReactGA.event({
        category: 'User',
        action: 'user_logged_in',
        label: window.location.href,
    });
}

const generationStepOne = () => {
    ReactGA.event({
        category: 'Generation',
        action: 'step_one_finished',
        label: window.location.href,
    });
}

const generationStepTwo = () => {
    ReactGA.event({
        category: 'Generation',
        action: 'step_two_finished',
        label: window.location.href,
    });
}

const bookViewFlipPage = () => {
    ReactGA.event({
        category: 'Book',
        action: 'flip_page',
        label: window.location.href,
    });
}

const bookPrint = () => {
    ReactGA.event({
        category: 'Book',
        action: 'print_button_clicked',
        label: window.location.href,
    });
}

const bookViewDownloadFreePdf = () => {
    ReactGA.event({
        category: 'Book',
        action: 'download_free_pdf',
        label: window.location.href,
    });
}

const bookShareButton = () => {
    ReactGA.event({
        category: 'Book',
        action: 'share_button_clicked',
        label: window.location.href,
    });
}

const bookShareToPublic = () => {
    ReactGA.event({
        category: 'Book',
        action: 'make_book_public',
        label: window.location.href,
    });
}

const bookCopyLink = () => {
    ReactGA.event({
        category: 'Book',
        action: 'copy_book_link',
        label: window.location.href,
    });
}

const clickOnExampleBook = () => {
    ReactGA.event({
        category: 'Examples',
        action: 'click_on_example_book',
        label: window.location.href,
    });
}

const clickOnMyBooks = () => {
    ReactGA.event({
        category: 'My Books',
        action: 'click_on_my_book',
        label: window.location.href,
    });
}

const gaEvents = {
    userAuth,
    generationStepOne,
    generationStepTwo,
    bookViewFlipPage,
    bookPrint,
    bookViewDownloadFreePdf,
    clickOnExampleBook,
    clickOnMyBooks,
    bookShareButton,
    bookShareToPublic,
    bookCopyLink,
}

export { gaEvents }


