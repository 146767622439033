import styled from "styled-components";

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-between;
`;

export const FlipButton = styled.button`
  font-size: 2rem;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }

  svg {
    display: block;
    circle,
    path {
      stroke: #225599; // Change the stroke color
    }
  }
`;

export const ShareButton = styled.button`
  font-size: 2rem;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin-left: auto;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }

  svg {
    display: block;
  }
`;

export const DeleteButton = styled.button`
  font-size: 2rem;
  background-color: transparent;
  border: none;
  padding: 10px;
  margin: 20px;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  &:focus {
    outline: none;
  }

  svg {
    display: block;
  }
`;

export const TextBox = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%; // take the full width
  height: 100%; // take the full height
  font-size: 1.7vw;
  line-height: 1.8;
  font-family: "Kalam", cursive;
  color: black;
  overflow: auto;
  padding: 4%;
  background-color: #f8f7ed;
  // text-align: center;
`;

export const ImageContainer = styled.div`
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%; // take the full width
  height: 100%; // take the full height
  background-color: #f8f7ed;
`;

export const Image = styled.img`
  // max-width: 100%; // ensure the image does not exceed the container width
  // max-height: 100%; // ensure the image does not exceed the container height
  // background-color: #f8f7ed;
`;

export const BookPage = styled.div`
  position: relative;
  width: 100%; // Take up full width of parent
  height: 100%;
  // overflow: hidden; // Any content exceeding this container will be hidden

  ::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background: url("public/right-side.svg") no-repeat center;
    background-size: contain;
    z-index: 1;
  }

  &::before {
    content: "";
    display: block;
    padding-top: 121%; // For A4 ratio 1:1.41
  }

  & > div {
    // directly selected children (in this case, TextBox or ImageContainer) will be absolutely positioned inside
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  // Shadows
  box-shadow: -4px 0 8px -2px rgba(0, 0, 0, 0.1),
    // Lighter shadow on the left side
    4px 0 8px -2px rgba(0, 0, 0, 0.2),
    // Darker shadow on the right side
    0 4px 8px -2px rgba(0, 0, 0, 0.1),
    // Shadow on the bottom
    0 -4px 8px -2px rgba(0, 0, 0, 0.1); // Shadow on the top
`;
