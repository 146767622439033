import { useEffect } from "react";
import { useStore } from "src/stores/storeContext";
import BooksList from "./BooksList";
import { observer } from "mobx-react";

const Examples = () => {
  const booksStore = useStore().booksStore;

  useEffect(() => {
    const getExamples = async () => {
      booksStore.fetchExamples();
    };
    getExamples();
  }, []);

  return (
    <BooksList
      books={booksStore.examples}
      title="Examples Gallery"
      id="examples"
    />
  );
};

export default observer(Examples);
