import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useStore } from "src/stores/storeContext";
import BooksList from "./BooksList";

import { observer } from "mobx-react";

const audience =
  process.env.REACT_APP_AUTH0_AUDIENCE || "default-audience-value";

const MyBooks = () => {
  const booksStore = useStore().booksStore;
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getBooks = async () => {
      const token = await getAccessTokenSilently({ audience } as any);
      booksStore.fetchMyBooks(token);
    };
    getBooks();
  }, []);

  return (
    <BooksList books={booksStore.my_books} title="My Books" id="mybooks" />
  );
};

export default observer(MyBooks);
