import React from "react";
import { Divider as MuiDivider } from "@mui/material";

type DividerProps = {
  width?: string;
};

const Divider = ({ width = "30%" }: DividerProps) => {
  return (
    <div style={{ width: "100%", marginBottom: "1rem" }}>
      <MuiDivider
        sx={{
          width: width,
          height: "5px",
          margin: "auto",
          backgroundColor: "primary.main",
          opacity: 0.25,
          borderRadius: "4px",
          marginBottom: "1rem",
        }}
      />
    </div>
  );
};

export default Divider;
