import { Button, Link } from "@mui/material";
import { styled } from "@mui/system";

const Error = styled("div")({
  fontSize: 40,
  width: "100%",
  color: "#353740",
  fontFamily: "'Kalam', cursive",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50vh",
});

const CustomLink = styled(Link)({
  marginLeft: "8px",
  marginRight: "8px",
});

const CustomH1 = styled("h1")(() => ({
  width: "100%", // Equivalent to w-full in Tailwind CSS
  padding: "8px", // Equivalent to my-2 in Tailwind CSS (8px * 2 = 16px)
  fontFamily: "Comic Sans MS",
  fontSize: "4rem", // Equivalent to text-6xl in Tailwind CSS
  fontWeight: "bold", // Equivalent to font-bold in Tailwind CSS
  lineHeight: "1.2", // Equivalent to leading-tight in Tailwind CSS
  textAlign: "center", // Equivalent to text-center in Tailwind CSS
  color: "var(--primary)", // Replace with your primary color variable
}));

// Styled Button
const CustomButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "150px",
  padding: "30px",
  paddingTop: "12px",
  paddingBottom: "12px",
  fontSize: "1.25rem",
  fontWeight: "500",
  variant: "contained",
  borderRadius: "15px", // Make the button more rounded
  border: "5px solid transparent",
  "&:hover": {},
}));

export { Error, CustomLink, CustomH1, CustomButton };
