import Gender from "./Gender";
import ColorPicker from "./ColorPicker";
import { useState, useRef } from "react";
import { useStore } from "src/stores/storeContext";
import * as React from "react";

type KidAppearancePopUPProps = {
  isOpen: boolean;
  onClose: () => void;
};

const KidAppearancePopup = ({ isOpen, onClose }: KidAppearancePopUPProps) => {
  const inputStore = useStore().inputStore;
  const contentRef = useRef<HTMLDivElement>(null);

  // This handler is for the backdrop (the entire fixed div)
  const handleBackdropClick = (event) => {
    if (contentRef.current && !contentRef.current.contains(event.target)) {
      closePopUp(); // Close if click is outside of popup content, but within the backdrop
    }
  };

  const eyes_colors = [
    { name: "grey", rgb: "138,150,161", link: "Grey_eyes.png" },
    { name: "blue", rgb: "58,127,164", link: "Blue_eyes.png" },
    { name: "green", rgb: "97,162,81", link: "Green_eyes.png" },
    { name: "brown", rgb: "159,103,47", link: "Brown_eyes.png" },
  ];

  const hair_colors = [
    { name: "blond", rgb: "255,208,99", link: "Yellow_hair.png" },
    { name: "red", rgb: "255,111,35", link: "Orange_hair.png" },
    { name: "brown", rgb: "133,58,35", link: "Brown_hair.png" },
    { name: "black", rgb: "58,36,20", link: "Black_hair.png" },
  ];

  const skin_colors = [
    { name: "light", rgb: "255,224,207", link: "Light_skin.png" },
    { name: "fair", rgb: "241,185,160", link: "Fair_skin.png" },
    { name: "dark", rgb: "113,61,35", link: "Dark_skin.png" },
    { name: "black", rgb: "52,38,37", link: "Cacao_skin.png" },
  ];

  const [selectedHairColor, setSelectedHairColor] = useState(hair_colors[2]);
  const [selectedEyesColor, setSelectedEyesColor] = useState(eyes_colors[1]);
  const [selectedSkinColor, setSelectedSkinColor] = useState(skin_colors[1]);
  const [isBoy, setIsBoy] = useState(true);

  const closePopUp = () => {
    inputStore.setKidAppearanceInput(
      "a " +
      (isBoy ? "boy" : "girl") +
      " with a " +
      selectedHairColor?.name +
      " hair, " +
      selectedEyesColor?.name +
      " eyes, and " +
      selectedSkinColor?.name +
      " skin"
    );

    onClose();
  };

  if (!isOpen) {
    return null;
  }
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      onClick={handleBackdropClick} // Attach the handler here
    >
      <div className="w-full max-w-lg"
        onClick={(e) => e.stopPropagation()} // Stop propagation to prevent the backdrop handler from closing the popup on content clicks
        ref={contentRef} // Apply the ref here for the content
      >
        <div className="flex items-center justify-center p-4 px-6">
          <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex flex-col items-center justify-center">
                <Gender isBoy={isBoy} setIsBoy={setIsBoy} />
                <p className="my-2">Skin color</p>
                <ColorPicker
                  colors={skin_colors}
                  selectedColor={selectedSkinColor}
                  setSelectedColor={setSelectedSkinColor}
                />
                <p className="my-2">Hair color</p>
                <ColorPicker
                  colors={hair_colors}
                  selectedColor={selectedHairColor}
                  setSelectedColor={setSelectedHairColor}
                />
                <p className="my-2">Eyes color</p>
                <ColorPicker
                  colors={eyes_colors}
                  selectedColor={selectedEyesColor}
                  setSelectedColor={setSelectedEyesColor}
                />
              </div>
              <div className="mt-4">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-md text-white py-1 px-2 mt-4 rounded"
                  onClick={closePopUp}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KidAppearancePopup;
