import { observer } from "mobx-react";
import { Button } from "reactstrap";
import { InputWrapper, CustomTextField } from "./formStyles";
import * as React from "react";

import { useStore } from "src/stores/storeContext";
import { useState } from "react";
import { ImMagicWand } from "react-icons/im";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { gaEvents } from "src/utils/events";
import { debounce } from "lodash";
import CoreValuePopup from "src/components/form/coreValue/coreValuePopup";
import { MdModeEdit } from "react-icons/md";

const MAX_CHARACTERS = 500;

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
export interface StepTwoProps {
  setErroeMessage: (e: any) => void;
  onSubmited: (e: any) => void;
}

const SUGGESTIONS = [
  {
    bookname: "KIDNAME goes to the beach",
    kidinfo:
      "KIDNAME likes to go to the beach, and build sandcastles. KIDNAME likes to play the guitar and sing with his brother.",
    plot:
      "KIDNAME dreams of building the biggest sandcastle ever, and making new friends at the beach.",
  },
  {
    bookname: "KIDNAME learns to make a salad",
    kidinfo:
      "KIDNAME loves to help in the kitchen and is always curious about cooking.",
    plot:
      "KIDNAME learns to make a salad, and dreams to make a magnificant salad to her parents.",
  },
  {
    bookname: "KIDNAME learns to sing",
    kidinfo:
      "KIDNAME loves to sing and is always humming a tune.",
    plot:
      "KIDNAME learns to sing, and dreams to surprise his parents with a beatiful song.",
  },
  {
    bookname: "KIDNAME's Space Adventure",
    kidinfo:
      "KIDNAME is fascinated by the stars and planets.",
    plot:
      "One night, KIDNAME dreams of traveling through space, meeting friendly aliens, and exploring distant galaxies. This adventure fuels KIDNAME's imagination about the wonders of the universe.",
  },
  {
    bookname: "KIDNAME and the Magical Garden",
    kidinfo:
      "KIDNAME likes to play in the garden and loves to take care of the plants. He has curly hair and big smile.",
    plot:
      "KIDNAME discovers a secret garden filled with talking animals and magical plants. In this enchanting place, KIDNAME learns about nature, the importance of caring for the environment, and makes wonderful animal friends.",
  },
  {
    bookname: "KIDNAME's Time Traveling Adventure",
    kidinfo:
      "KIDNAME is a curious child who loves history and adventure.",
    plot:
      "KIDNAME finds an old clock that has the power to travel through time. KIDNAME visits different historical periods, learning about history first-hand and experiencing adventures with historical figures.",

  },
  {
    bookname: "KIDNAME and the Mystery of the Lost Puppy",
    kidinfo:
      "KIDNAME is a kind-hearted child who loves animals.",
    plot:
      "KIDNAME finds a lost puppy in the park and embarks on a journey to find its home. Along the way, KIDNAME learns about kindness, responsibility, and the joy of helping others.",

  },
  {
    bookname: "KIDNAME's Starry Dreams",
    kidinfo:
      "KIDNAME is a dreamy and curious child who loves the night sky. KIDNAME has a telescope and loves to stargaze.",
    plot:
      "KIDNAME, using a telescope as a constant companion and a love for space documentaries, dreams of becoming an astronaut.",
  },
  {
    bookname: "KIDNAME's Historical Quest",
    kidinfo:
      "KIDNAME, a young history buff, is always buried in history books, fascinated by ancient civilizations.",
    plot:
      "KIDNAME discovers a hidden treasure map in the attic, leading to a historical adventure. KIDNAME travels through time, learning about different cultures and historical events, and solving puzzles to uncover the treasure.",
  },
  {
    bookname: "KIDNAME's Ocean Blue Adventure",
    kidinfo:
      "KIDNAME is a young ocean enthusiast who loves to explore the beach and collect seashells.",
    plot:
      "KIDNAME embarks on a journey to save a coral reef from pollution and overfishing. KIDNAME learns about the importance of ocean conservation and the wonders of marine life.",
  },
  {
    bookname: "KIDNAME's Culinary Wonders",
    kidinfo:
      "KIDNAME is a young foodie who loves to experiment with different ingredients and flavors. KIDNAME has a passion for cooking and baking.",
    plot:
      "KIDNAME discovers a magical cookbook that transports him to different culinary worlds. KIDNAME learns about diverse cuisines, the joy of cooking, and the importance of sharing meals with loved ones.",
  },
];

const StepTwo: React.FC<StepTwoProps> = (props) => {
  const inputStore = useStore().inputStore;
  const [bookNameInput, setBookNameInput] = useState("");
  const [kidDetailsInput, setKidDetailsInput] = useState("");
  const [plot, setPlot] = useState("");
  const [isCoreValueOpen, setIsCoreValueOpen] = useState(false);
  // Function to check if all fields are empty
  const areFieldsEmpty = () => {
    return (
      inputStore.bookNameInput?.trim() === "" &&
      inputStore.kidDetailsInput?.trim() === "" &&
      inputStore.plotInput?.trim() === "" &&
      inputStore.kidAppearanceInput?.trim() === ""
    );
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior
      if (areFieldsEmpty()) {
        const suggestButton = document.getElementById("suggestButton");
        if (suggestButton) {
          // Trigger the 'Continue' button click event
          suggestButton.click();
        }
      } else {
        const submitButton = document.getElementById("submitButton");
        if (submitButton) {
          // Trigger the 'Continue' button click event
          submitButton.click();
        }
      }
    }
  };

  const handleSuggestions = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const randomSuggestion =
      SUGGESTIONS[Math.floor(Math.random() * SUGGESTIONS.length)];
    if (randomSuggestion) {
      // replace the KIDNAME with the actual name in the suggestion
      const kidName = inputStore.nameInput;
      const kidNameRegex = new RegExp("KIDNAME", "g");
      let bookname = randomSuggestion.bookname.replace(kidNameRegex, kidName);
      let plotInfo = randomSuggestion.plot.replace(kidNameRegex, kidName);
      setPlot(plotInfo);
      setBookNameInput(bookname);
    } else {
      setKidDetailsInput(`${inputStore.nameInput} likes to go to the beach, and build sandcastles.
              ${inputStore.nameInput} has brown eyes and dark hair.`);

      setBookNameInput(`${inputStore.nameInput} goes to the beach`);
    }
    props.setErroeMessage("");
  };

  const handleOnClick = (e: any) => {
    inputStore.setBookNameInput(bookNameInput);
    inputStore.setKidDetailsInput(kidDetailsInput);
    inputStore.setPlotInput(plot);
    props.setErroeMessage("");
    props.onSubmited(e);
    gaEvents.generationStepTwo();
    e.stopPropagation();
  }

  const debouncedHandleOnClick = debounce(handleOnClick, 300);

  return (
    <InputWrapper>
      <CustomTextField
        id="outlined-basic"
        data-testid="book_name"
        label="Book Name"
        name="book_name"
        type="text"
        value={bookNameInput}
        onChange={(e) => {
          if (e.target.value.length > 70) {
            props.setErroeMessage("Maximum characters for book name allowed is 70. Please make it shorter.");
            return;
          }
          setBookNameInput(e.target.value);
          props.setErroeMessage("");
        }}
        onKeyDown={handleKeyPress}
      />

      <CustomTextField
        id="outlined-multiline-static"
        data-testid="kidDetails"
        label={`Some details on ${inputStore.nameInput}`}
        name="kidDetails"
        type="text"
        value={kidDetailsInput}
        onChange={(e) => {
          if (e.target.value.length > MAX_CHARACTERS) {
            props.setErroeMessage("Maximum characters for kid details allowed is 500. Please make it shorter.");
            return;
          }
          setKidDetailsInput(e.target.value);
          props.setErroeMessage("");
        }}
        InputProps={{
          style: { paddingLeft: "10px", paddingTop: "10px" },
        }}
        multiline // Enable multiline
        rows={1} // Set the number of rows
        onKeyDown={handleKeyPress}
      />
      <CustomTextField
        id="outlined-multiline-static"
        data-testid="plot"
        label={'Plot of the story'}
        name="plot"
        type="text"
        value={plot}
        InputProps={{
          style: { paddingLeft: "10px", paddingTop: "10px" },
          endAdornment: (
            <InputAdornment position="end">
              <CustomTooltip
                title={
                  <React.Fragment>
                    <em>{"Suggest random book!"}</em>
                  </React.Fragment>
                }
              >
                <Button className='cursor-default'>
                  <ImMagicWand
                    className="mr-3 text-indigo-500 cursor-pointer md:text-3xl sm:text-3xl xs:text-2xl"
                    onClick={handleSuggestions}
                  />
                </Button>
              </CustomTooltip>
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          if (e.target.value.length > MAX_CHARACTERS) {
            props.setErroeMessage("Maximum characters for plot allowed is 500. Please make it shorter.");
            return;
          }
          setPlot(e.target.value);
          props.setErroeMessage("");
        }}
        multiline // Enable multiline
        rows={1} // Set the number of rows
        onKeyDown={handleKeyPress}
      />
      <CustomTextField
        id="outlined-basic"
        data-testid="corevalue"
        label="Book values"
        name="corevalue"
        type="text"
        value={inputStore.coreValueInput}
        onKeyDown={handleKeyPress}
        onClick={() => setIsCoreValueOpen(true)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MdModeEdit
                className="mr-5 text-2xl text-indigo-500 cursor-pointer"
                onClick={() => setIsCoreValueOpen(true)}
              />
            </InputAdornment>
          ),
        }}
      />
      <CoreValuePopup isOpen={isCoreValueOpen}
        onClose={() => setIsCoreValueOpen(false)}></CoreValuePopup>
      <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
        <Button
          onClick={() => {
            inputStore.setContentState("stepone");
            props.setErroeMessage("");
          }}
          className={`w-auto px-8 py-3 border border-transparent text-base font-medium rounded-lg text-background bg-primary hover:bg-border md:py-4 md:text-lg md:px-10 shadow`}
        >
          Back
        </Button>
        <Button
          id="submitButton"
          data-testid="submit"
          onClick={debouncedHandleOnClick}
          className={`w-auto  px-8 py-3 border border-transparent text-base font-medium rounded-lg text-background bg-primary hover:bg-border md:py-4 md:text-lg md:px-10 shadow`}
        >
          Generate
        </Button>
      </div>
    </InputWrapper>
  );
};

export default observer(StepTwo);

