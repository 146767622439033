import { useEffect } from "react";

const ErrorIcon = () => {
  return (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <svg
        className="h-6 w-6 text-red-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
    </div>
  );
};

type BookFailedProps = {
  isOpen: boolean;
  onClose: () => void;
};

const BookFailedModal = ({ isOpen, onClose }: BookFailedProps) => {
  useEffect(() => {
    const handleCloseModal = (event) => {
      if (isOpen && event.target.closest(".modal-content") === null) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleCloseModal);
    }

    return () => {
      document.removeEventListener("click", handleCloseModal);
    };
  }, [isOpen, onClose]);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="w-full max-w-lg">
        <div className="flex items-center justify-center p-4 px-6">
          <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex flex-col items-center justify-center">
                <ErrorIcon />
                <h3 className="mt-4 text-lg font-semibold leading-6 text-gray-900">
                  Failed to create book, please try again
                </h3>
                <p>
                  This is probably due to the kid's details you wrote. Note that
                  we have a strict NSFW policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookFailedModal;
