import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PrintModal from "./PrintModal";
import { GiBookCover } from "react-icons/gi";
import { gaEvents } from "src/utils/events";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const PrintButton = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleClick = () => {
    gaEvents.bookPrint();
    setIsModalOpen(true);
  };

  return (
    <div>
      <CustomTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Print Your Book</Typography>
            <em>{"Print your book and get it delivered to your home"}</em>
          </React.Fragment>
        }
      >
        <Button>
          <GiBookCover onClick={handleClick} className="w-5 sm:w-14 sm:h-8 text-[#225599] cursor-pointer" />
        </Button>
      </CustomTooltip>
      <PrintModal isOpen={isModalOpen} onClose={onClose} />
    </div>
  );
};

export default PrintButton;
