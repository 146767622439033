import SpinnerSVG from "src/components/book/loading.svg";
import { Book } from "src/stores/booksStore";
import BookCoverViewer from "./BookCoverViewer";
import { CustomButton } from "src/Styles";
import { Grid } from "@mui/material";
import { useStore } from "src/stores/storeContext";
import { useState } from "react";
import Divider from "src/utils/Divider";

interface BooksListProps {
  books: Book[];
  title: string;
  id: string;
}

const BooksList: React.FC<BooksListProps> = ({
  books,
  title,
  id,
}: BooksListProps) => {
  const booksStore = useStore().booksStore;
  const [bookNum, setBookNum] = useState(6);

  if (books.length === 0 && !booksStore.isLoading) {
    return null;
  }
  return (
    <section className="bg-[#E4F3FF] min-h-screen" id={id}>
      <div>
        <h1 className="text-center tracking-tight font-extrabold text-3xl md:text-4xl lg:text-6xl pt-6">
          <span className="block xl:inline text-blue-900">{title}</span>
        </h1>
        <Divider />
        <div className="flex flex-col gap-10 justify-center items-center">
          {booksStore.isLoading ? (
            /** books are loading... */
            <div>
              <img
                src={SpinnerSVG}
                alt={
                  booksStore.currentBookIsFetchingImages
                    ? "Fetching images spinner"
                    : "Loading spinner"
                }
              />
            </div>
          ) : (
            /** all books are fetched */
            <div className="m-6 w-full" >
              <Grid container spacing={3} justifyContent="center" columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {books.slice(0, bookNum).map((book, index) => (
                  <Grid
                    key={index}
                    item
                    xs={4} sm={4} md={4}
                    // xs={12} // Displaying one book in a row for all sizes
                    // sm={6} // Displaying two books in a row for small devices (sm)
                    // md={4} // Displaying 3 book in a row for medium devices (md)
                    // xl={3} // Displaying 4 book in a row for large devices (xl\)
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <div className="p-8">
                      <BookCoverViewer book={book} />
                    </div>
                  </Grid>
                ))}
              </Grid>
              {books.length > bookNum && (
                <div className="flex justify-center mt-15">
                  <CustomButton onClick={() => setBookNum(bookNum * 2)}>
                    See More
                  </CustomButton>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section >
  );
};

export default BooksList;
