import React from "react";
import Header from "src/components/home/Header";
import { BookViewer } from "./BookViewer";
import LazyShow from "src/utils/LazyShow";
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet
import { useAuth0 } from '@auth0/auth0-react';
const feedback_client_id = process.env.REACT_APP_FEEDBACK_CLIENT_ID; // '65c3b5f6afd87c00021b6449';


const Book = () => {
  const {
    user, isAuthenticated
  } = useAuth0();

  return (
    <>
      {isAuthenticated && (
        <Feedback primaryColor="rgba(0, 39, 120, 0.85)"
          projectId={feedback_client_id} emailDefaultValue={user?.email} emailRequired={true} email={true} ></Feedback>)
      }
      <LazyShow>
        <section>
          <Header />
          <div
            className="flex justify-center"
            style={{
              background: "url(book_bg.jpg) center center/cover no-repeat",
              opacity: 0.9,
            }}
          >
            <BookViewer />
          </div>
        </section>
      </LazyShow>
    </>

  );
};

export default Book;
