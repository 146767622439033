import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, scroller } from "react-scroll";
import { useAuth0 } from "@auth0/auth0-react";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();

  const company = {
    name: "AI kid's book generator",
    logo: "/kid.png",
  };

  const navigation_logedin = [
    {
      name: "Start Now",
      href: "generate",
    },
    {
      name: "My Books",
      href: "mybooks",
    },
    {
      name: "Examples",
      href: "examples",
    },

    {
      name: "Pricing",
      href: "pricing",
    },
  ];

  const navigation_logedout = [
    {
      name: "Start Now",
      href: "generate",
    },
    {
      name: "Examples",
      href: "examples",
    },
    {
      name: "Pricing",
      href: "pricing",
    },
  ];

  const handleScrollToSection = (href) => {
    navigate(`/#${href}`);
    scroller.scrollTo(href, {
      duration: 750,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <footer className="bg-white dark:bg-gray-900">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="#" className="flex items-center">
              <img src={company.logo} className="h-8 me-3" alt="Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
                {company.name}
              </span>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2">
            <div>
              <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Quick Links
              </h2>
              <div>
                {isAuthenticated ? (
                  <div className="flex flex-col text-gray-500 dark:text-gray-400 font-medium">
                    {navigation_logedin.map((item) => (
                      <div key={item.name} className='mb-2'>
                        {location.pathname !== "/" ? (
                          // If not on the home route ("/"), navigate to home ("/") and scroll to href
                          <a
                            key={item.name}
                            onClick={(e) => {
                              e.preventDefault();
                              handleScrollToSection(item.href);
                            }}
                            className="cursor-pointer hover:underline"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            spy={true}
                            active="active"
                            smooth={true}
                            duration={750}
                            key={item.name}
                            to={item.href}
                            href={item.href}
                            className="cursor-pointer hover:underline "
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex flex-col">
                    {navigation_logedout.map((item) => (
                      <div key={item.name} className='mb-2'>
                        {location.pathname !== "/" ? (
                          // If not on the home route ("/"), navigate to home ("/") and scroll to href
                          <a
                            key={item.name}
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              handleScrollToSection(item.href);
                            }}
                            className="cursor-pointer hover:underline"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            spy={true}
                            active="active"
                            smooth={true}
                            duration={750}
                            key={item.name}
                            to={item.href}
                            href={item.href}
                            className="cursor-pointer hover:underline "
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div>
              <h2 className="mb-2 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal & Contact
              </h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-2">
                  <a href="/policy/privacy" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/policy/privacy" className="hover:underline">
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a href="mailto:kaduriomri@gmail.com" className="hover:underline">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2024{" "}
            <a href="https://kidstorygen.com/" className="hover:underline">
              kidstorygen
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
