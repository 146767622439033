// @ts-nocheck
import * as Sentry from "@sentry/react";
import { useState, useRef, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Modal from "react-modal";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import HTMLFlipBook from "react-pageflip";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { FaTrash, FaFilePdf, FaPlus } from "react-icons/fa";
import { FcPrevious, FcNext } from "react-icons/fc";
import { FaCheck } from "react-icons/fa";
import { FaShareSquare } from "react-icons/fa";
import { MdModeEditOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

import { Error, CustomLink } from "src/Styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { CustomTextField } from "src/components/form/formStyles";

import SpinnerSVG from "src/components/book/loading.svg";
import ImgSpinnerSVG from "src/components/book/img-spinner.svg";

import {
  ButtonContainer,
  FlipButton,
  TextBox,
  ImageContainer,
  Image,
  BookPage,
  ShareButton,
} from "./BookViewerStyles";

import { Book } from "../../stores/booksStore";
import { useStore } from "src/stores/storeContext";
import PrintButton from "./printButton";
import PrintModal from "./PrintModal";
import { gaEvents } from "src/utils/events";
import { set } from 'react-ga';
import PdfModal from "./PdfModal";

const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const serverAdress = process.env.REACT_APP_SERVER_ADDRESS;
const cdnAddress = "https://images.kidstorygen.com";

const BookViewer = () => {
  const stores = useStore();
  const booksStore = stores.booksStore;
  const productsStore = stores.productsStore;
  const { id: paramId } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);
  const [book, setBook] = useState<Book | undefined>(undefined);

  useEffect(() => {
    const getBook = async () => {
      setIsLoading(true);
      let token = "";
      try {
        token = await getAccessTokenSilently({ audience } as any);
      } catch (error) {
        console.log(error);
      }
      if (paramId) {
        let book = await booksStore.fetchBookById(paramId, token); // fetch book from server (with empty images - question marks)
        setBook(book);
      } else if (booksStore.currentBookId) {
        //book is generated now, so it is in my books
        let book = await booksStore.getBookById(booksStore.currentBookId);
        setBook(book);
      } else return;
      setIsLoading(false);
    };
    getBook();
  }, [paramId, booksStore.currentBookId]);

  /** The book is loading (fetching from server) */
  if (isLoading) {
    return (
      <div className="spinner-container">
        <img src={SpinnerSVG} alt="Loading spinner" />
      </div>
    );
  }

  /** if no book was selected or no book id from url */
  if (!book) {
    return (
      <>
        <Error>
          No book found ☹️ <CustomLink href="/">click here</CustomLink> to
          generate one!
        </Error>
      </>
    );
  }

  return (
    <div className="w-full h-full items-center flex flex-col" id="book-viewer">
      <h1 className="text-center text-2xl py-4">{book.name}</h1>
      <MyBook book={book} />
    </div>
  );
};

const sanitizeText = (text: any) => {
  const regex = /[\/\\{}]/g; // Matches /, \, {, and }
  return text.replace(regex, "");
};

const TextPage = ({
  text,
  updatePageText,
  user_can_edit,
}: {
  text: string;
  updatePageText: any;
  user_can_edit?: boolean;
}) => {
  const [editable, setEditable] = useState(false);
  const [btnText, setBtnText] = useState("Edit");
  const [pageText, setPageText] = useState(text);

  const handleEdit = (e) => {
    if (editable) {
      setBtnText("Edit");
      updatePageText(pageText);
    } else {
      setBtnText("Save");
    }
    setEditable(!editable);
  };

  const handleTextChange = (e) => {
    setPageText(e.target.value); // Update the pageText state with the new text
  };

  return (
    <BookPage className='relative grid grid-flow-row bg-[#f8f7ed]' style={{ gridTemplateRows: 'repeat(7, minmax(0, 1fr))' }}>
      <TextBox className='row-start-1 row-end-7 flex flex-col h-full w-full justify-center'>
        {editable ? (
          <div className="flex flex-grid w-full h-4/5 bg-gray-100 p-4 rounded-md ">
            <textarea
              className="w-full h-full outline-none bg-transparent"
              onMouseUp={(e) => e.stopPropagation()}
              onTouchEnd={(e) => e.stopPropagation()}
              value={pageText} // Control the input with state
              onChange={handleTextChange} // Handle changes
            />
          </div>
        ) : (
          <p>{pageText}</p>
        )}
      </TextBox>
      <div className='row-start-7 row-end-8  mt-3'>
        {user_can_edit && <Button
          name='edit'
          style={{
            position: 'absolute',
            bottom: '3vh', // Adjust as needed to ensure visibility
            left: '50%',
            transform: 'translateX(-50%)',
            // zIndex: 20 // Ensure this is above ::before's z-index
          }}
          onClick={(e) => { handleEdit(e); e.stopPropagation(); }}
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()} className='z-20'
        >
          {btnText === 'Edit' ?
            <CiEdit
              className="w-5 sm:w-14 sm:h-8 text-[#225599] mx-0 cursor-pointer z-20"
              name='edit'
            />
            : <FaCheck
              name='edit'
              className="w-5 sm:w-14 sm:h-8 text-[#225599] mx-0 cursor-pointer z-20" />}
        </Button>
        }
      </div>
    </BookPage >
  );
}

const ImagePage = ({ generateNewImage, user_can_edit, index, selected_image_index, n_images_for_page, selectImage, book_id, i }: { generateNewImage: any, user_can_edit?: boolean, index: number, selected_image_index: number, n_images_for_page: number, selectImage: any, book_id: string, i: number }) => {
  const [n_images_generated, setNImagesGenerated] = useState(0);
  const [selectedImageIdx, setSelectedImageIdx] = useState(selected_image_index);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);

  const image_url_for_index = (index: number) => {
    if (isGeneratingImage && index === n_images_for_page + n_images_generated - 1) {
      //return 'img-spinner.svg'
      return ImgSpinnerSVG
    }
    else
      // return ImgSpinnerSVG
      return index > 0 ? `${cdnAddress}/${book_id}/image_${i}_${index}.jpg` : `${cdnAddress}/${book_id}/image_${i}.jpg`;
    // return 
  }

  const handleOnGenerateImage = async (e) => {
    if (n_images_generated + n_images_for_page >= 3) {
      //show modal to buy more images
      return;
    }
    setNImagesGenerated(n_images_generated + 1);
    setIsGeneratingImage(true);
    let res = await generateNewImage(index);
    setIsGeneratingImage(false);
    if (res) {
      setSelectedImageIdx(n_images_for_page + n_images_generated);
    }
    e.stopPropagation();
  }

  return (
    <BookPage className='grid grid-flow-row bg-[#f8f7ed]' style={{ gridTemplateRows: 'repeat(7, minmax(0, 1fr))' }}>
      <ImageContainer className='row-start-1 row-end-7'>
        <img src={image_url_for_index(selectedImageIdx)}
          className='object-fill shadow-2xl' />
      </ImageContainer>
      <div className='row-start-7 row-end-8 bg-[#f8f7ed] flex flex-row flex-row-reverse mt-3'
      >
        {user_can_edit &&

          <Button
            name='edit'
            className='z-20 cursor-pointer'
            onClick={isGeneratingImage ? () => { } : (e) => { handleOnGenerateImage(e); e.stopPropagation(); }}
          >
            <FaPlus
              name='edit'
              className="w-5 sm:w-14 sm:h-8 text-[#225599] mx-0 cursor-pointer z-20 mx-2"
            ></FaPlus>
          </Button>
        }
        <div className='h-full items-center flex-row-reverse flex'>
          {Array.from({ length: n_images_for_page + n_images_generated }, (_, i) => i).map((index) =>
            <img
              className={` mx-2 h-4/5 w-auto object-fill rounded-lg cursor-pointer`}
              key={index}
              src={image_url_for_index(index)}
              name='thumbnails'
              style={{
                boxShadow: selectedImageIdx === index ? '0 0 0 4px #AEDEFC' : 'none'
              }}
              onClick={(e) => {
                if (user_can_edit) {
                  selectImage(i, index);
                }
                setSelectedImageIdx(index);
                e.stopPropagation();
              }}
            />
          )}
        </div>
      </div>
    </BookPage >
  );
};

const alternate_image_and_text_components = (
  book_id: string,
  texts: string[],
  ref: any,
  updatePageText: any,
  generateNewImage: any,
  user_can_edit?: boolean,
  selected_image_indices?: number[],
  n_images_for_page?: number[],
  selectImage?: any
) => {
  let result: React.JSX.Element[] = [];
  let page_editable = texts.map((text) => false);
  for (let i = 0; i < texts.length; i++) {
    let other_images = selected_image_indices ? Array.from({ length: n_images_for_page[i] }, (_, i) => i).filter((index) => index !== selected_image_indices[i]) : [];
    let all_images = selected_image_indices ? Array.from({ length: n_images_for_page[i] }, (_, i) => i) : [];

    result.push(
      <ImagePage generateNewImage={generateNewImage} user_can_edit={user_can_edit} index={i} selected_image_index={selected_image_indices[i]} n_images_for_page={n_images_for_page[i]} selectImage={selectImage} book_id={book_id} i={i} />
    );

    const sanitizedText = sanitizeText(texts[i]);
    result.push(
      <TextPage
        text={sanitizedText}
        updatePageText={updatePageText(i)}
        user_can_edit={user_can_edit}
      />
    );
  }
  return result;
};

interface MyBookProps {
  book: Book;
}

const MyBook: React.FC<MyBookProps> = ({ book }: MyBookProps) => {
  const stores = useStore();
  const productsStore = stores.productsStore;
  const booksStore = stores.booksStore;
  const book_ref = useRef<any>(null);
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const updatePageText = (index: number) => async (text: string) => {
    console.log("updating page text", index, text);
    // "api/book/:bookId/:pageId" - send POST request to update the text
    try {
      const token = await getAccessTokenSilently({ audience } as any);
      const response = await fetch(`${serverAdress}/book/${book.id}/${index}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ text: text }),
      });

      if (response.ok) {
        console.log("Text updated successfully");
      } else {
        console.error("Failed to update text:", await response.text());
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  const generatePageNewImage = async (index: number) => {
    console.log("generating new image", index);
    try {
      const token = await getAccessTokenSilently({ audience } as any);
      const response = await fetch(
        `${serverAdress}/generate/image/${book.id}/${index}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        console.log("Image generated successfully");
        return true;
      } else {
        console.error("Failed to generate image:", await response.text());
        return false;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
  book.selected_image_indices = (book.selected_image_indices && book.selected_image_indices.length > 0) ? book.selected_image_indices : Array.from({ length: book.pages.length }, () => "0").join(',')
  const [selectedImageIndices, setSelectedImageIndices] = useState(book.selected_image_indices.split(','));

  book.n_images_for_page = book.n_images_for_page || Array.from({ length: book.pages.length }, () => "1").join(',');

  const selectImage = async (index: number, image_index: number) => {
    console.log("selecting image", index, image_index);
    try {
      const token = await getAccessTokenSilently({ audience } as any);
      const response = await fetch(
        `${serverAdress}/book/${book.id}/${index}/image/${image_index}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        console.log("Image selected successfully");
        let new_selected_image_indices = book.selected_image_indices.split(',');
        new_selected_image_indices[index] = image_index;
        book.selected_image_indices = new_selected_image_indices.join(',');
        setSelectedImageIndices(new_selected_image_indices);
      } else {
        console.error("Failed to select image:", await response.text());
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }


  const p = alternate_image_and_text_components(
    book.id,
    book.pages,
    book_ref,
    updatePageText,
    generatePageNewImage,
    book.user_email === user?.email,
    selectedImageIndices.map(Number),
    book.n_images_for_page.split(',').map(Number),
    selectImage
  )

  const [pages, setPages] = useState(p);
  const [open, setOpen] = useState(false);
  const shareLink = `${window.location.protocol}//${window.location.host}/book/${book.id}`;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const linkRef = useRef(null);
  const [isLastPage, setIsLastPage] = useState(false);
  const [clipBoardMessage, setClipBoardMessage] = useState("");
  const [isPublic, setIsPublic] = useState(
    book.permission.toLowerCase() === "public"
  );
  const [pageIndex, setPageIndex] = useState(1);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  const handleKeySelection = useCallback((event: KeyboardEvent) => {
    const { key } = event;
    const arrowRightPressed = key === "ArrowRight";
    const arrowLeftPressed = key === "ArrowLeft";

    if (arrowRightPressed) {
      event.preventDefault();
      book_ref.current.pageFlip().flipNext();
    } else if (arrowLeftPressed) {
      event.preventDefault();
      book_ref.current.pageFlip().flipPrev();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeySelection);
    return () => {
      document.removeEventListener("keydown", handleKeySelection);
    };
  }, [handleKeySelection]);

  const checkTarget = (target) => {
    if ((['a', 'button', 'textarea', 'svg', 'path'].includes((target as HTMLElement).tagName.toLowerCase())) || (target.name && target.name.includes('thumbnails'))) {
      return false;
    }
    console.log((target as HTMLElement).tagName.toLowerCase());
    return true;
  };

  const onMouseDown = (e) => {
    if (checkTarget(e.target)) {
      const pos = book_ref.current
        .pageFlip()
        .ui.getMousePos(e.clientX, e.clientY);
      book_ref.current.pageFlip().ui.app.startUserTouch(pos);
      e.preventDefault();
    }
  };

  const onMouseUp = (e) => {
    return book_ref.current.pageFlip().ui.onMouseUp(e);
  };

  const onMouseMove = (e) => {
    return book_ref.current.pageFlip().ui.onMouseMove(e);
  };

  const [pageFlipAvailable, setPageFlipAvailable] = useState(false);

  useEffect(() => {
    // Function to check if pageFlip() is available
    const checkPageFlipAvailability = () => {
      const isPageFlipAvailable = !!book_ref.current?.pageFlip();
      setPageFlipAvailable(isPageFlipAvailable);
    };

    // Start the interval only if pageFlip() is not yet available
    if (!pageFlipAvailable) {
      const interval = setInterval(checkPageFlipAvailability, 250);
      // Return a cleanup function that clears the interval
      return () => clearInterval(interval);
    }
  }, [pageFlipAvailable]);

  useEffect(() => {
    if (!pageFlipAvailable) return;
    const distElement = book_ref.current.pageFlip().ui.distElement;
    distElement.addEventListener("mousedown", onMouseDown);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);

    return () => {
      distElement.removeEventListener("mousedown", onMouseDown);
      document.removeEventListener("mouseup", onMouseUp);
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [onMouseDown, onMouseUp, onMouseMove, pageFlipAvailable]);

  const onFlip = () => {
    if (!book_ref.current) return;
    gaEvents.bookViewFlipPage();
    let currentPageIndex =
      book_ref.current.pageFlip().pages.currentPageIndex + 1;
    setPageIndex(Math.floor(currentPageIndex / 2) + 1);
    let nPages = book_ref.current.pageFlip().pages.pages.length;
    if (currentPageIndex === nPages) {
      setTimeout(() => setIsLastPage(true), 500);
    }
  };

  const flipNext = () => {
    book_ref.current.pageFlip().flipNext();
  };

  const flipPrev = () => {
    book_ref.current.pageFlip().flipPrev();
  };

  const handleClickOpen = () => {
    setOpen(true);
    console.log(open);
  };

  const handleShare = () => {
    gaEvents.bookShareButton();
    setModalIsOpen(true);
  };

  const handleCopyToClipboard = () => {
    if (linkRef.current) {
      (linkRef.current as any).select();
      document.execCommand("copy");
      setClipBoardMessage(
        "Link has been copied - now you can share it with your loved ones :)"
      );
      // Reset the message after a delay, if desired
      setTimeout(() => setClipBoardMessage(""), 3000); // Message disappears after 7 seconds
      gaEvents.bookCopyLink();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      const token = await getAccessTokenSilently({ audience } as any);
      await fetch(`${serverAdress}/book/${book.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    setOpen(false);
  };

  const handlePermissionChange = async (event) => {
    gaEvents.bookShareButton();
    const newPermission = event.target.value;
    if (newPermission === "Public") {
      setIsPublic(true);
    } else {
      setIsPublic(false);
    }
    try {
      const token = await getAccessTokenSilently({ audience } as any);
      const response = await fetch(
        `${serverAdress}/book/${book.id}/permissions`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ permission: newPermission }),
        }
      );

      if (response.ok) {
        console.log("Permissions updated successfully");
        book.permission = newPermission.toLowerCase();
      } else {
        console.error("Failed to update permissions:", await response.text());
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className="w-2/3 h-auto items-center flex flex-col mb-20" >
      < HTMLFlipBook
        ref={book_ref}
        width={400}
        height={450}
        size="stretch"
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={true}
        swipeDistance={300}
        flippingTime={550}
        onFlip={onFlip}
        useMouseEvents={false}
        clickEventForward={true}
      >
        <div>
          <BookPage>
            <ImageContainer className='flex items-center'>
              <Image
                src={`${cdnAddress}/${book.id}/cover.jpg`}
                className='object-cover'
              />
            </ImageContainer>
          </BookPage>
        </div>
        {
          pages.map((page, index) => {
            return (
              <div data-density="soft" key={index}>
                {page}
              </div>
            );
          })
        }
        <div data-density="soft">
          <BookPage>
            <TextBox className='flex justify-center items-center'>
              <p>THE END</p>
            </TextBox>
          </BookPage>
        </div>
      </HTMLFlipBook >
      <div className="flex justify-between pt-8 items-center w-full">
        <div className="flex items-center space-x-6">
          <FcPrevious
            onClick={flipPrev}
            className="w-5 sm:w-14 sm:h-8 text-black sm:mr-4 cursor-pointer"
          />
        </div>
        <div className="absolute left-1/2 transform -translate-x-1/2">
          {pageIndex} / {book.pages.length + 2}
        </div>
        <div className="flex items-center space-x-6">
          {isAuthenticated && <PrintButton />}

          {isAuthenticated && (
            <FaShareSquare
              onClick={handleShare}
              className="w-5 sm:w-14 sm:h-8 text-[#225599] cursor-pointer"
            />
          )}

          <a onClick={() => setIsPdfModalOpen(true)}>
            <FaFilePdf className="w-5 sm:w-14 sm:h-8 text-[#225599] mx-0 cursor-pointer" />
          </a>
          <FcNext
            onClick={flipNext}
            className="w-5 sm:w-14 sm:h-8 text-black sm:ml-4 cursor-pointer"
          />
        </div>
      </div>
      <PdfModal
        isOpen={isPdfModalOpen}
        onClose={() => setIsPdfModalOpen(false)}
        bookId={book.id}
        bookName={book.name}
        price={2}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Book"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the book "{book.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Share Book Modal"
        style={{
          content: {
            width: "43vw", // 50% of the viewport width
            maxWidth: "calc(100% - 2rem)", // Ensure there's at least a little margin
            height: "fit-content",
            padding: "1rem", // Use rem units for padding
            overflow: "auto",
            margin: "auto",
          },
        }} // Adjust the modal size
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h5">Who can read your book?</Typography>

          <FormControl component="fieldset">
            <RadioGroup
              col
              aria-label="position"
              name="position"
              defaultValue={book.permission.replace("p", "P")}
            >
              <FormControlLabel
                value="Private"
                control={<Radio color="primary" />}
                label="Only me"
                onChange={handlePermissionChange}
              />
              <FormControlLabel
                value="Public"
                control={<Radio color="primary" />}
                label="Everyone with the link"
                onChange={handlePermissionChange}
              ></FormControlLabel>
            </RadioGroup>
          </FormControl>
          {isPublic && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <TextField
                inputRef={linkRef}
                value={shareLink}
                variant="outlined"
                className="w-2/3"
                size="small"
                InputProps={{
                  readOnly: true,
                  style: { backgroundColor: "lightgray" },
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                size="Large"
                onClick={handleCopyToClipboard}
              >
                <svg
                  class="js-clipboard-default w-4 h-4 group-hover:rotate-6 transition"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                </svg>
                <svg
                  class="js-clipboard-success hidden w-4 h-4 text-blue-600"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
                Copy
              </Button>
            </Box>
          )}
          {clipBoardMessage && (
            <div
              className="p-4 mb-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
              role="alert"
            >
              <span className="font-medium">{clipBoardMessage}</span>
            </div>
          )}
          <Button variant="text" onClick={() => setModalIsOpen(false)}>
            Close
          </Button>
        </Box>
      </Modal>
      <PrintModal isOpen={isLastPage} onClose={() => setIsLastPage(false)} />
    </div>
  );
};

export { MyBook, BookViewer };
