import { useEffect } from "react";
import { Link } from "react-scroll";

const ErrorIcon = () => {
  return (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
      <svg
        className="h-6 w-6 text-red-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
        />
      </svg>
    </div>
  );
};

const CheckIcon = () => {
  return (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
      <svg
        className="h-6 w-6 text-green-600"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5 13l4 4L19 7"
        />
      </svg>
    </div>
  );
};

type PaymentPopUPProps = {
  isOpen: boolean;
  onClose: () => void;
  isSuccessful: boolean;
};

const PaymentPopUP = ({ isOpen, onClose, isSuccessful }: PaymentPopUPProps) => {
  useEffect(() => {
    const handleCloseModal = (event) => {
      if (isOpen && event.target.closest(".modal-content") === null) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleCloseModal);
    }

    return () => {
      document.removeEventListener("click", handleCloseModal);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="w-full max-w-lg">
        <div className="flex items-center justify-center p-4 px-6">
          <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex flex-col items-center justify-center">
                {isSuccessful ? <CheckIcon /> : <ErrorIcon />}
                <h3 className="mt-4 text-lg font-semibold leading-6 text-gray-900">
                  {isSuccessful ? "Payment Successful" : "Payment Failed"}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {isSuccessful ? (
                    <>
                      <p>Hooray! We received your payment.</p>
                      <p>Go ahead and write a kid book with AI!</p>
                    </>
                  ) : (
                    "Oops! Something went wrong. Please try again."
                  )}
                </p>
              </div>
              {isSuccessful && (
                <div className="mt-4">
                  <Link
                    spy={true}
                    active="active"
                    smooth={true}
                    duration={750}
                    key="generate"
                    to="generate"
                    href="generate"
                    onClick={onClose}
                    className="text-black hover:bg-gray-500 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                  >
                    Go To Generate
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPopUP;
