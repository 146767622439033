import React, { useEffect } from "react";
import Pricing from "./components/Pricing/Pricing";
import MyBooks from "./components/booksList/MyBooks";
import Examples from "./components/booksList/examples";
import Home from "./components/home/Home";
import LazyShow from "./utils/LazyShow";
import GenerateBook from "./components/form/GenerateBook";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./components/home/Footer";
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet
const feedback_client_id = process.env.REACT_APP_FEEDBACK_CLIENT_ID; // '65c3b5f6afd87c00021b6449';

const Main = () => {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    const href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      window.history.replaceState(null, `#${href}`);
    }
  }, []);

  return (
    <div id="app">
      {isAuthenticated && (
        <Feedback primaryColor="rgba(0, 39, 120, 0.85)"
          projectId={feedback_client_id} emailDefaultValue={user?.email} emailRequired={true} email={true} ></Feedback>)
      }
      <LazyShow>
        <Home />
      </LazyShow>
      <LazyShow>
        <GenerateBook />
      </LazyShow>
      {isAuthenticated && (
        <LazyShow>
          <MyBooks />
        </LazyShow>
      )}
      <LazyShow>
        <Examples />
      </LazyShow>
      <LazyShow>
        <Pricing />
      </LazyShow>
      <LazyShow>
        <Footer />
      </LazyShow>
    </div>
  );
};

export default Main;
