import React from "react";
import Header from "./Header";
import MainHero from "./MainHero";

const Home = () => {
  return (
    <section className="min-h-screen grid grid-rows-5">
      <div className="row-end-1 shadow-inner-xl">
        <Header />
      </div>

      < div
        className="row-start-1 row-end-6 flex justify-center bg-center"
        style={{
          background: "url(home.jpeg) center center/cover no-repeat",
          opacity: 0.9,
        }}
      >
        <MainHero />
      </div>
    </section >
  );
};

export default Home;
