import React from "react";
import { Link } from "react-scroll";

const MainHero = () => {
  const title = "Put Your Kid In The Center";
  const subtitle = "Of Their Own Story";
  const primaryAction = {
    text: "Start Now",
    href: "generate",
  };
  const secondaryAction = {
    text: "Examples",
    href: "examples",
  };

  return (
    <div className="h-full w-full sm:w-3/4 mx-2 flex flex-col justify-center items-center">
      <h1 className="text-center font-extrabold text-5xl sm:text-5xl md:text-6xl lg:text-7xl">
        <span className="block text-[#ffffef] drop-shadow-2xl" style={{ textShadow: "2px 2px black" }}>
          {title}
        </span>
        <span className={`block text-[#ffffef] drop-shadow-2xl`} style={{ textShadow: "2px 2px black" }}>{subtitle}</span>
      </h1>
      <div className="mt-8 sm:mt-10 flex justify-center items-center text-center flex-col sm:flex-row">
        <img src="freearrow.png" className='hidden sm:block w-18 h-16 translate-y-8 translate-x-10'>
        </img>
        <p style={{ position: "absolute", fontFamily: "Kalam" }} className="hidden sm:block translate-y-24 -translate-x-44 font-bold text-2xl sm:text-xl md:text-4xl lg:text-3xl">
          Try for Free!
        </p>
        <Link
          spy={true}
          active="active"
          smooth={true}
          duration={750}
          key={primaryAction.text}
          href={primaryAction.href}
          to={primaryAction.href}
          className={`flex items-center justify-center text-center font-bold rounded-3xl text-lg text-black bg-[#f7b954] hover:bg-[#f7c26a] py-3 md:py-5 px-6 sm:px-8 md:px-10 sm:mx-4 my-4  transition duration-300 transform hover:scale-105`}
        >

          {primaryAction.text}
        </Link>

        <Link
          spy={true}
          active="active"
          smooth={true}
          duration={1000}
          key={secondaryAction.text}
          href={secondaryAction.href}
          to={secondaryAction.href}
          className={`flex items-center justify-center text-center font-bold rounded-3xl text-lg text-black bg-[#98d9c2] hover:bg-[#abedc6] py-3 md:py-4 px-6 sm:px-8 md:px-10 sm:mx-4 my-4  transition duration-300 transform hover:scale-105`}
        >
          {secondaryAction.text}
        </Link>
      </div>
    </div >
  );
};

export default MainHero;
