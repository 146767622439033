import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";

export interface Book {
  id: string; // Assuming id is a string as used in fetchImages
  name: string; // Name of the book
  coverImage: string; // Cover image of the book
  pages: string[]; // Number of pages in the book
  images: string[];
  visualDescriptions: string[];
  permission: string; // Permission level of the book
  user_email: string; // Email of the user who created the book
  selected_image_indices: number[];
}

export class BookClass implements Book {
  id;
  name;
  coverImage;
  pages;
  images;
  visualDescriptions;
  permission;
  user_email;
  selected_image_indices: number[];
  constructor(id: string) {
    this.id = id;
    this.name = "";
    this.coverImage = "";
    this.pages = [];
    this.images = [];
    this.visualDescriptions = [];
    this.permission = "private";
    this.user_email = "";
    this.selected_image_indices = [];
  }
}

const FETCH_BOOKS_API = `${process.env.REACT_APP_SERVER_ADDRESS}/mybooks`;
const FETCH_EXAMPLE_BOOKS_API = `${process.env.REACT_APP_SERVER_ADDRESS}/examples/books`;
const FETCH_BOOK_BY_ID_API = `${process.env.REACT_APP_SERVER_ADDRESS}/book`;

class BooksStore {
  my_books: Book[] = [];
  examples: Book[] = [];
  currentBookId: string | null = null;
  isLoading: boolean = false; // loading all books
  currentBookIsFetchingImages: boolean = false; // loading images to current selected book

  constructor() {
    makeAutoObservable(this);
  }

  hasBookById(id: string): boolean {
    return this.my_books.some((book) => book.id === id);
  }

  getBookById(id: string): Book | undefined {
    return this.my_books.find((book) => book.id === id);
  }

  public fetchMyBooks = async (token: any) => {
    this.setLoadingStatus(true);

    try {
      const response = await fetch(FETCH_BOOKS_API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });

      if (!response.ok) {
        // Check if response status is not okay (e.g. not in 200-299 range)
        throw new Error(
          `Server responded with ${response.status}: ${response.statusText}`
        );
      }

      const res = await response.json();

      this.setBooks(res);
    } catch (error) {
      Sentry.captureException(error);

      this.setLoadingStatus(false);
    }
    this.setLoadingStatus(false);
  };

  public fetchExamples = async () => {
    this.setLoadingStatus(true);

    try {
      const response = await fetch(FETCH_EXAMPLE_BOOKS_API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
      });

      if (!response.ok) {
        // Check if response status is not okay (e.g. not in 200-299 range)
        throw new Error(
          `Server responded with ${response.status}: ${response.statusText}`
        );
      }

      const res = await response.json();

      this.setExamples(res);
    } catch (error) {
      Sentry.captureException(error);

      this.setLoadingStatus(false);
    }
    this.setLoadingStatus(false);
  };

  public fetchBookById = async (id: string, token: any) => {
    {
      try {
        let res = await fetch(`${FETCH_BOOK_BY_ID_API}/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const book = await res.json(); // book details without images
        // set images to a list of 'question_marks.png' with the same length as book.pages
        book.images = Array(book.pages.length).fill("loading");
        this.setCurrentBookId(book.id);
        this.setLoadingStatus(false);
        return book;
      } catch (err) {
        Sentry.captureException(err);
        this.setLoadingStatus(false);
      }
    }
  };

  public fetchBookImageByPageNumber = async (
    bookId: string,
    pageNum: number
  ) => {
    try {
      const res = await fetch(
        `${FETCH_BOOK_BY_ID_API}/${bookId}/page/${pageNum}`
      );
      const data = await res.json();
      return data.image;
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  setBooks = async (newBooks: Book[]) => {
    runInAction(() => {
      this.my_books = newBooks;
    });
  };

  setExamples = async (examples: Book[]) => {
    runInAction(() => {
      this.examples = examples;
    });
  };

  setCurrentBookId = async (bookId: string) => {
    runInAction(() => {
      this.currentBookId = bookId;
    });
  };

  setLoadingStatus = async (status: boolean) => {
    runInAction(() => {
      this.isLoading = status;
    });
  };

  setCurrentBookFetchingImagesStatus = async (status: boolean) => {
    runInAction(() => {
      this.currentBookIsFetchingImages = status;
    });
  };
}

export default BooksStore;
