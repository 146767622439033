import { makeAutoObservable } from "mobx";
import { Book } from "./booksStore";
const GET_PRODUCT_API = `${process.env.REACT_APP_SERVER_ADDRESS}/products/`;
const CREATE_PDF_API = `${process.env.REACT_APP_SERVER_ADDRESS}/pdf`;

export interface Product {
  id: string;
  price: number;
  create_amount: number;
  print_amount: number;
}

class ProductsStore {
  constructor() {
    makeAutoObservable(this);
  }

  public getProduct = async (productId: string) => {
    try {
      const response = await fetch(GET_PRODUCT_API + productId, {
        method: "GET",
        mode: "cors",
      });
      const data = await response.json();
      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  public convertBookToPdf = async (token: any, book: Book) => {
    try {
      const response = await fetch(CREATE_PDF_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          texts: book.pages,
          bookName: book.name,
          bookUUID: book.id,
        }),
      });

      if (response.status !== 200) {
        throw new Error(`Request failed with status ${response.status}`);
      }
      const blob = await response.blob(); // Extract blob data from the response

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "book.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
    }
  };
}

export default ProductsStore;
