const ColorPicker = ({ colors, selectedColor, setSelectedColor }) => {
  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  return (
    <div className="flex items-center">
      <img
        src={selectedColor.link} // Replace with the actual path
        alt={selectedColor.name}
        width={80}
        className="flex items-cnter justify-center"
      />
      <div className="flex justify-around">
        {colors.map((color) => (
          <div
            key={color.name}
            className={`w-8 h-8 rounded-full cursor-pointer mx-3 ${
              selectedColor.name === color.name ? "border-2 border-black" : ""
            }`}
            style={{ backgroundColor: `rgb(${color.rgb})` }}
            onClick={() => handleColorChange(color)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ColorPicker;
