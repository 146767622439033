import * as Sentry from "@sentry/react";
import { useState, useEffect } from "react";
import StepperBar from "./stepperBar";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import LoadingGif from "./loadingGif";
import Generating from "./Generating";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { FormContainer } from "./formStyles";
import { gaEvents } from "src/utils/events";

import { useStore } from "src/stores/storeContext";
import PaymentModal from "./PaymentModal";
import BookFailedModal from "./bookFailed";

const Form = () => {
  const { inputStore, booksStore, userStore, productsStore } = useStore();
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently } =
    useAuth0();
  const [errorMessage, setErrorMessage] = useState(""); // error message state
  const navigate = useNavigate();
  const [isUserAllowedToCreateBook, setIsUserAllowedToCreateBook] =
    useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [oneBookPrice, setOneBookPrice] = useState(3);
  const [booksAmount, setBooksAmount] = useState(0);
  const [printsAmount, setPrintsAmount] = useState(0);
  const [isFailedBook, setIsFailedBook] = useState(false);

  useEffect(() => {
    // every 750 ms get the generation satus from server, and get the information to the current status from the server to client.
    const fetchGenerationStatusWithToken = async () => {
      const token = await getAccessTokenSilently();
      await inputStore.fetchGenerationStatus(token); // Run function immediately
      if (
        !inputStore.isGenerating &&
        !inputStore.isLoading &&
        inputStore.newBook
      ) {
        booksStore.setCurrentBookId(inputStore.newBook.id);
        inputStore.setContentState("stepone");
        inputStore.clearInputs();
        booksStore.my_books.push(inputStore.newBook);
        navigate(`/book`);
      }
    };

    const fetchOneBookPrice = async () => {
      try {
        const productCreate1 = await productsStore.getProduct("create1");
        setOneBookPrice(productCreate1.price);
        setBooksAmount(productCreate1.books_amount);
        setPrintsAmount(productCreate1.prints_amont);
      } catch (error) {
        // Handle errors if getProduct fails
        Sentry.captureException(error);
      }
    };
    fetchOneBookPrice();
    let intervalId: any;
    if (inputStore.isGenerating && inputStore.generationStatus < 3) {
      fetchGenerationStatusWithToken();
      intervalId = setInterval(inputStore.fetchGenerationStatus, 750);
    } else if (inputStore.isGenerating && inputStore.generationStatus === 3) {
      fetchGenerationStatusWithToken();
    }
    // Clear the interval when the effect is cleaned up
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [
    inputStore.isGenerating,
    inputStore.generationStatus,
    inputStore.isFetchingGenerationStatus,
  ]);

  const handleContinue = (e: any) => {
    e.preventDefault(); // Prevents the default form submission behavior.
    if (
      !inputStore.nameInput ||
      !inputStore.parentsNameInput ||
      !inputStore.ageInput
    ) {
      setErrorMessage("Fill in all fields to have a great book!");
    } else if (Number(inputStore.ageInput) < 0) {
      // setAgeInput(0);
      setErrorMessage("Age cannot be less than 0.");
    } else if (Number(inputStore.ageInput) > 16) {
      // setAgeInput(16);
      setErrorMessage("Age cannot be more than 16.");
    } else {
      setErrorMessage(""); // reset error message if the input is valid
      gaEvents.generationStepOne();
      inputStore.setContentState("steptwo");
    }

  };

  const contentMap = {
    stepone: (
      <StepOne
        handleContinue={handleContinue}
        setErrorMessage={setErrorMessage}
      />
    ),
    steptwo: <StepTwo setErroeMessage={setErrorMessage} onSubmited={onSubmit} />,
    loading: <LoadingGif />,
    generating: <Generating />,
  };

  if (inputStore.isLoading) {
    inputStore.setContentState("loading");
  } else if (inputStore.isGenerating) {
    inputStore.setContentState("generating");
  } else if (inputStore.generationStatus === 2) {
    inputStore.setContentState("steptwo");
  }

  async function onSubmit(event: any) {
    event.preventDefault();
    //Check for all fields to be filled
    if (!inputStore.bookNameInput || !inputStore.kidDetailsInput) {
      setErrorMessage("Please fill in all fields before continuing.");
      return;
    }
    setErrorMessage("");
    let token;
    if (!isAuthenticated) {
      await loginWithPopup();
      try {
        token = await getAccessTokenSilently();
      } catch (e) {
        return;
      }
      await userStore.setUser(token);
    }
    else
      token = await getAccessTokenSilently();
    // Check if user has sufficient books quota
    const isUserAllowedToCreateBook_response =
      await userStore.isUserAllowedToCreateBook(token);
    setIsUserAllowedToCreateBook(isUserAllowedToCreateBook_response);
    if (!isUserAllowedToCreateBook_response) {
      // open modal with paypal button to pay for another book
      setIsModalOpen(true);
      return;
    }
    let generationStarted = await inputStore.onSubmit(token);
    if (!generationStarted) {
      //show error - we failed to create this book this time. Please try again.
      setIsFailedBook(true);
      return;
    }
    // update user books quota
    userStore.updateBooksQuota(-1);
  }

  async function onCloseFailedBook() {
    setIsFailedBook(false);
    inputStore.setContentState("stepone");
  }

  return (
    <div>
      {inputStore.contentState === "stepone" ||
        inputStore.contentState === "steptwo" ? (
        <div>
          {/* Rendering content for 'stepone' and 'steptwo' */}
          <FormContainer onSubmit={(e) => { e.preventDefault(); }}>
            <StepperBar />
            {contentMap[inputStore.contentState]}
          </FormContainer>
          {/* Display error message if available */}
          {errorMessage && (
            <div className="text-red-500 text-center">😞 {errorMessage}</div>
          )}
          {!isUserAllowedToCreateBook && (
            <PaymentModal
              isOpen={isModalOpen}
              booksAmount={booksAmount}
              printsAmount={printsAmount}
              onClose={() => setIsModalOpen(false)}
              price={oneBookPrice}
            />
          )}
        </div>
      ) : (
        <div>
          {isFailedBook && (
            <BookFailedModal
              isOpen={isFailedBook}
              onClose={onCloseFailedBook}
            />
          )}
          {contentMap[inputStore.contentState]}
        </div>
      )
      }
    </div >
  );
};

export default observer(Form);
