import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { observer } from "mobx-react";
import { styled } from "@mui/material/styles";
import { useStore } from "src/stores/storeContext";

const steps = ["Kids Info", "Plot", "Generation"];

const CustomStepper = styled(Stepper)(() => ({
  "& .Mui-active .MuiSvgIcon-root": { color: "#05658C" },
  "& .Mui-completed .MuiSvgIcon-root": { color: "#05658C" },
}));

const StepperBar = () => {
  const { inputStore } = useStore();

  const activeStep =
    inputStore.contentState == "stepone"
      ? 0
      : inputStore.contentState === "steptwo"
        ? 1
        : 2;
  return (
    <Box sx={{ width: "80%" }}>
      <CustomStepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </CustomStepper>
    </Box>
  );
};

export default observer(StepperBar);
