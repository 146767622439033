import React from "react";
import Divider from "src/utils/Divider";
import Form from "src/components/form/form";
import Clouds from "src/assets/clouds-background.svg";

const GenerateBook = () => {
  const title = "Create your own";
  const subtitle = "AI book";

  return (
    <section
      id="generate"
      style={{
        backgroundImage: `url(${Clouds})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        marginTop: 0,
        minHeight: "90vh",
      }}
    >
      <div className="flex justify-center items-center pt-4 md:pt-10 lg:pt-2">
        <h1 className="text-center text-4xl tracking-tight font-extrabold text-gray-900 md:text-5xl lg:text-6xl">
          <span className="block xl:inline">{title}</span>{" "}
          <span className={`block text-primary xl:inline `}>{subtitle}</span>
        </h1>
      </div>
      <Divider />
      <div className="mt-10 md:mt-16">
        <Form />
      </div>
    </section>
  );
};

export default GenerateBook;
