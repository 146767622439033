import React, { useEffect, useState } from "react";
import PayPalPayment from "./PayPalPayment";
import PaymentPopUp from "./paymentPopUp";

import { useStore } from "src/stores/storeContext";

const Pricing = () => {
  const productsStore = useStore().productsStore;

  const [modalOpen, setModalOpen] = useState(false);
  const [isPaymentSucceeded, setIsPaymentSucceeded] = useState(false);

  const title = "Pricing";
  const firstPlan = {
    name: "Free",
    price: "$0",
    priceDetails: "for one book",
    features: ["1 book", "Book sharing", "No support", "No printing"],
  };
  const [secondPlan, setSecondPlan] = useState({
    id: "create10",
    name: "Book Writer",
    price: "",
    booksAmount: 0,
    printsAmount: 0,
    priceDetails: "for 5 books",
    features: ["5 books", "Book sharing", "Priority Support", "No printing"],
  });

  const [thirdPlan, setThirdPlan] = useState({
    id: "print1",
    name: "Book Printer",
    price: "",
    booksAmount: 0,
    printsAmount: 0,
    priceDetails: "for printed book",
    features: [
      "1 printed book - hard cover",
      "Premium color printing",
      "Book sharing",
      "Book Delivery included",
      "24/7 Support",
    ],
  });

  useEffect(() => {
    const updatePlans = async () => {
      try {
        const productCreate10 = await productsStore.getProduct("create10");
        setSecondPlan({
          ...secondPlan,
          price: productCreate10.price + "$",
          booksAmount: productCreate10.books_amount,
          printsAmount: productCreate10.prints_amont,
        });

        const productPrint1 = await productsStore.getProduct("print1");
        setThirdPlan({
          ...thirdPlan,
          price: productPrint1.price + "$",
          booksAmount: productPrint1.books_amount,
          printsAmount: productPrint1.prints_amont,
        });
      } catch (error) {
        // Handle errors if getProduct fails
        console.error("Error fetching products:", error);
      }
    };
    updatePlans();
  }, []);

  return (
    <section className={`bg-background py-8`} id="pricing">
      <div className={`container mx-auto px-2 pt-4 pb-12 text-primary`}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-primary`}
        >
          {title}
        </h1>
        <div className={`w-full mb-4`}>
          <div
            className={`h-1 mx-auto bg-primary w-64 opacity-25 my-0 py-0 rounded-t`}
          ></div>
        </div>
        <div
          className={`flex flex-col sm:flex-row justify-center pt-12 my-12 sm:my-4`}
        >
          {/** first plan */}
          <div
            className={`flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-background mt-4`}
          >
            <div
              className={`flex-1 bg-background text-gray-600 rounded-t rounded-b-none overflow-hidden shadow`}
            >
              <div className={`p-8 text-3xl font-bold text-center border-b-4`}>
                {firstPlan?.name}
              </div>
              <ul className={`w-full text-center text-sm`}>
                {firstPlan?.features.map((feature) => (
                  <li
                    className={`border-b py-4`}
                    key={`${firstPlan.name}-${feature}`}
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`flex-none mt-auto bg-background rounded-b rounded-t-none overflow-hidden shadow p-6`}
            >
              <div
                className={`w-full pt-6 text-3xl text-gray-600 font-bold text-center`}
              >
                {firstPlan?.price}
                <span className={`text-base`}> {firstPlan?.priceDetails}</span>
              </div>
              <div className={`flex flex-col sm:flex-row justify-center`}></div>
            </div>
          </div>
          {/** second plan */}
          <div
            className={`flex flex-col w-5/6 lg:w-1/3 mx-auto lg:mx-0 rounded-lg bg-background mt-4 sm:-mt-6 shadow-lg z-10`}
          >
            <div
              className={`flex-1 bg-background rounded-t rounded-b-none overflow-hidden shadow`}
            >
              <div className={`w-full p-8 text-3xl font-bold text-center`}>
                {secondPlan?.name}
              </div>
              <div
                className={`h-1 w-full bg-primary my-0 py-0 rounded-t`}
              ></div>
              <ul className={`w-full text-center text-base font-bold`}>
                {secondPlan?.features.map((feature) => (
                  <li
                    className={`border-b py-4`}
                    key={`${secondPlan?.name}-${feature}`}
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`flex-none mt-auto bg-background rounded-b rounded-t-none overflow-hidden shadow p-6`}
            >
              <div className={`w-full pt-6 text-4xl font-bold text-center`}>
                {secondPlan?.price}
                <span className={`text-base`}> {secondPlan?.priceDetails}</span>
              </div>
              <div className={`flex flex-col sm:flex-row justify-center`}>
                <PayPalPayment
                  product_id={secondPlan?.id}
                  booksAmount={secondPlan?.booksAmount}
                  printsAmount={secondPlan?.printsAmount}
                  setModalOpen={setModalOpen}
                  setIsPaymentSucceeded={setIsPaymentSucceeded}
                />
                <PaymentPopUp
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                  isSuccessful={isPaymentSucceeded}
                />
              </div>
            </div>
          </div>
          {/** third plan */}
          <div
            className={`flex flex-col w-5/6 lg:w-1/4 mx-auto lg:mx-0 rounded-none lg:rounded-l-lg bg-primary mt-4`}
          >
            <div
              className={`flex-1 bg-background text-gray-600 rounded-t rounded-b-none overflow-hidden shadow`}
            >
              <div className={`p-8 text-3xl font-bold text-center border-b-4`}>
                {thirdPlan?.name}
              </div>
              <ul className={`w-full text-center text-sm`}>
                {thirdPlan?.features.map((feature) => (
                  <li
                    className={`border-b py-4`}
                    key={`${thirdPlan?.name}-${feature}`}
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={`flex-none mt-auto bg-background rounded-b rounded-t-none overflow-hidden shadow p-6`}
            >
              <div
                className={`w-full pt-6 text-3xl text-gray-600 font-bold text-center`}
              >
                {thirdPlan?.price}
                <span className={`text-base`}> {thirdPlan?.priceDetails}</span>
              </div>
              <div className={`flex flex-col md:flex-row justify-center`}>
                <PayPalPayment
                  product_id={thirdPlan?.id}
                  booksAmount={thirdPlan?.booksAmount}
                  printsAmount={thirdPlan?.printsAmount}
                  setModalOpen={setModalOpen}
                  setIsPaymentSucceeded={setIsPaymentSucceeded}
                />
                <PaymentPopUp
                  isOpen={modalOpen}
                  onClose={() => setModalOpen(false)}
                  isSuccessful={isPaymentSucceeded}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
