import { ThemeProvider } from "@material-tailwind/react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import "src/app.css";
import Auth0ProviderWithNavigation from "src/utils/Auth0ProviderWithNavigation";
import Book from "src/components/book/Book";
import { StoreProvider } from "src/stores/storeContext";
import Main from "src/Main";
import InputStore from "src/stores/inputStore";
import BooksStore from "src/stores/booksStore";
import UserStore from "src/stores/userStore";
import ProductsStore from "src/stores/productsStore";
const paypal_client_id = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const inputStore = new InputStore();
const booksStore = new BooksStore();
const userStore = new UserStore();
const productsStore = new ProductsStore();

function App() {
  const initialOptions = {
    "client-id": paypal_client_id,
    currency: "USD",
    intent: "capture",
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <StoreProvider
        inputStore={inputStore}
        booksStore={booksStore}
        userStore={userStore}
        productsStore={productsStore}
      >
        <ThemeProvider>
          <BrowserRouter history={history}>
            <Auth0ProviderWithNavigation>
              <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/book" element={<Book />} />
                <Route path="/book/:id" element={<Book />} />
              </Routes>
            </Auth0ProviderWithNavigation>
          </BrowserRouter>
        </ThemeProvider>
      </StoreProvider>
    </PayPalScriptProvider>
  );
}

export default App;
