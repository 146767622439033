import { observer } from "mobx-react";
import { InputWrapper, CustomTextField } from "./formStyles";

import { useStore } from "src/stores/storeContext";
import { Button } from "reactstrap";
import { useState } from "react";
import KidAppearancePopup from "./KidAppearance/kidAppearancePopup";
// import { MdEdit } from "react-icons/md";
import { MdModeEdit } from "react-icons/md";

import InputAdornment from "@mui/material/InputAdornment";
const MAX_CHARACTERS = 30;

export interface StepOneProps {
  handleContinue: (e: any) => void;
  setErrorMessage: (e: any) => void;
}

const StepOne: React.FC<StepOneProps> = (props) => {
  const inputStore = useStore().inputStore;
  const [isKidAppearanceOpen, setIsKidAppearanceOpen] = useState(false);

  // age input handler
  const handleAgeInput = (e: any) => {
    e.preventDefault(); // Prevents the default form submission behavior.
    const { value } = e.target;
    if (value < 0) {
      // setAgeInput(0);
      props.setErrorMessage("Age cannot be less than 0.");
      return false;
    } else if (value > 16) {
      // setAgeInput(16);
      props.setErrorMessage("Age cannot be more than 16.");
      return false;
    } else {
      inputStore.setAgeInput(value);
      props.setErrorMessage(""); // reset error message if the input is valid
      return true;
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior
      // Trigger the 'Continue' button click event
      const continueButton = document.getElementById("continueButton");
      if (continueButton) {
        continueButton.click();
      }
    }
  };

  const handleSelectAppearance = () => {
    setIsKidAppearanceOpen(true);
  };

  return (
    <>
      <InputWrapper>
        <CustomTextField
          data-testid="kid_name"
          label="Kid's Name"
          name="name"
          type="text"
          value={inputStore.nameInput}
          onChange={(e) => {
            if (e.target.value.length > MAX_CHARACTERS) {
              props.setErrorMessage(`Name cannot be more than ${MAX_CHARACTERS} characters.`);
              return;
            }
            inputStore.setNameInput(e.target.value)
          }
          }

          onKeyDown={handleKeyPress}
        // variant="outlined"
        />
        <CustomTextField
          data-testid="parents_name"
          label="Parents' Names"
          name="parentsName"
          type="text"
          value={inputStore.parentsNameInput}
          onChange={(e) => {
            if (e.target.value.length > MAX_CHARACTERS) {
              props.setErrorMessage(`Parents' Names cannot be more than ${MAX_CHARACTERS} characters.`);
              return;
            }
            inputStore.setParentsNameInput(e.target.value)
          }
          }
          onKeyDown={handleKeyPress}
        // variant="outlined"
        />
        <CustomTextField
          data-testid="age"
          label="Kid's Age"
          name="age"
          type="number"
          value={inputStore.ageInput}
          onChange={handleAgeInput}
          onKeyDown={handleKeyPress}
        />

        <CustomTextField
          data-testid="kidappearance"
          label="Kid Appearance"
          name="kid appearance"
          type="text"
          className="text-grey-500"
          value={inputStore.kidAppearanceInput}
          onKeyDown={handleKeyPress}
          onClick={handleSelectAppearance}
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "rgba(0,0,0,0.8)",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MdModeEdit
                  className="mr-5 text-2xl text-indigo-500 cursor-pointer"
                  onClick={handleSelectAppearance}
                />
              </InputAdornment>
            ),
          }}
        />

        <Button
          id="continueButton"
          data-testid="continue"
          onClick={(e) => {
            props.handleContinue(e);
          }}
          className={`w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-lg text-background bg-primary hover:bg-border mb-8 md:py-4 md:text-lg md:px-10 shadow`}
        >
          Continue
        </Button>
      </InputWrapper>
      <KidAppearancePopup
        isOpen={isKidAppearanceOpen}
        onClose={() => setIsKidAppearanceOpen(false)}
      />
    </>
  );
};

export default observer(StepOne);
