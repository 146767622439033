import { useState, useRef } from "react";
import { useStore } from "src/stores/storeContext";
import * as React from "react";
import CoreValueSelector from 'src/components/form/coreValue/coreValuePicker';

type coreValuePopUPProps = {
    isOpen: boolean;
    onClose: () => void;
};

const CoreValuePopup = ({ isOpen, onClose }: coreValuePopUPProps) => {
    const inputStore = useStore().inputStore;

    const [coreValue, setCoreValue] = useState("cooperation");
    const contentRef = useRef<HTMLDivElement>(null);


    // This handler is for the backdrop (the entire fixed div)
    const handleBackdropClick = (event) => {
        if (contentRef.current && !contentRef.current.contains(event.target)) {
            closePopUp(); // Close if click is outside of popup content, but within the backdrop
        }
    };
    // Custom hook to handle outside clicks

    const closePopUp = () => {
        setCoreValue(coreValue);
        inputStore.setCoreValueInput(coreValue);
        onClose();
    };

    if (!isOpen) {
        return null;
    }
    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
            onClick={handleBackdropClick} // Attach the handler here
        >
            <div
                className="w-max p-4 px-6 rounded-lg bg-white text-center shadow-xl"
                onClick={(e) => e.stopPropagation()} // Stop propagation to prevent the backdrop handler from closing the popup on content clicks
                ref={contentRef} // Apply the ref here for the content
            >
                {/* Popup content */}
                {/* <h2 className="text-2xl font-bold">Choose a core value</h2> */}
                <CoreValueSelector />
                <div className="mt-4">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-md text-white py-1 px-2 mt-4 rounded"
                        onClick={() => onClose()}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CoreValuePopup;
