import { makeAutoObservable, runInAction } from "mobx";
const USER_CREATION_API = `${process.env.REACT_APP_SERVER_ADDRESS}/user`;
const BOOKS_QUOTA_API = `${process.env.REACT_APP_SERVER_ADDRESS}/user/books_quota`;
const PRINTS_QUOTA_API = `${process.env.REACT_APP_SERVER_ADDRESS}/user/prints_quota`;

class UserStore {
  email: string = "";
  booksQuota: number = 0;
  printsQuota: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  public getUser = async (token: string) => {
    try {
      const response = await fetch(USER_CREATION_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      const data = await response.json();
      this.email = data.email;
      this.booksQuota = data.books_quota;
      this.printsQuota = data.prints_quota;
    } catch (error) {
      console.log(error);
    }
  };

  public setUser = async (token: string) => {
    try {
      const response = await fetch(USER_CREATION_API, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      const data = await response.json();
      this.email = data.email;
      this.booksQuota = data.books_quota;
      this.printsQuota = data.prints_quota;
    } catch (error) {
      console.log(error);
    }
  };

  public isUserAllowedToCreateBook = async (token: string) => {
    try {
      const response = await fetch(BOOKS_QUOTA_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      const data = await response.json();
      return data; // true or false
    } catch (error) {
      console.log(error);
    }
  };

  public isUserAllowedToPrintBook = async (token: string) => {
    try {
      const response = await fetch(PRINTS_QUOTA_API, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      const data = await response.json();
      return data; // true or false
    } catch (error) {
      console.log(error);
    }
  };

  public updatePrintsQuota = (x: number) => {
    runInAction(() => {
      this.printsQuota += x;
    });
  };

  public updateBooksQuota = (x: number) => {
    runInAction(() => {
      this.booksQuota += x;
    });
  };
}

export default UserStore;
