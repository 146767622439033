import InputStore from "src/stores/inputStore";
import BooksStore from "src/stores/booksStore";
import UserStore from "src/stores/userStore";
import ProductsStore from "src/stores/productsStore";
import React, { ReactNode } from "react";

export type StoreContextType = {
  inputStore: InputStore;
  booksStore: BooksStore;
  userStore: UserStore;
  productsStore: ProductsStore;
};

// Create a context with the defined type
export const StoreContext = React.createContext<StoreContextType>(
  {} as StoreContextType
);

// Define prop types for StoryProvider
export type StoreProviderProps = {
  children: ReactNode;
  inputStore?: InputStore;
  booksStore?: BooksStore;
  userStore?: UserStore;
  productsStore?: ProductsStore;
};

// And create Provider with store.
export const StoreProvider: React.FC<StoreProviderProps> = ({
  children,
  inputStore,
  booksStore,
  userStore,
  productsStore,
}) => {
  const myBooksStore = booksStore ? booksStore : new BooksStore();
  const myInputStore = inputStore ? inputStore : new InputStore();
  const myUserStore = userStore ? userStore : new UserStore();
  const myProductsStore = productsStore ? productsStore : new ProductsStore();

  return (
    <StoreContext.Provider
      value={{
        inputStore: myInputStore,
        booksStore: myBooksStore,
        userStore: myUserStore,
        productsStore: myProductsStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => React.useContext(StoreContext);
