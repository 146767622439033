import { FaPrint } from "react-icons/fa";
import Button from "@mui/material/Button";

const Icon = () => {
  return (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
      <FaPrint />
    </div>
  );
};

type PrintModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PrintModal = ({ isOpen, onClose }: PrintModalProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="w-full max-w-lg">
        <div className="flex items-center justify-center p-4 px-6">
          <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex flex-col items-center justify-center">
                <Icon />
                <h3 className="mt-4 text-lg font-semibold leading-6 text-gray-900">
                  Print this book
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Print your book and get it delivered to your home!
                </p>
                <b>
                  Oh no, we are still working on this. We'll let you know when
                  this feature is ready.
                </b>
              </div>
              <div className="mt-4">
                <Button onClick={onClose}>close</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintModal;
