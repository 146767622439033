import React, { useState } from "react";
import { useStore } from 'src/stores/storeContext';


const CoreValueSelector = ({ }) => {
    const inputStore = useStore().inputStore;
    const images = [
        { src: "cooperation.jpg", text: "Cooperation" },
        { src: "responsible.jpg", text: "Responsability" },
        { src: "courage.jpg", text: "Courage" },
        { src: "hardwork.jpg", text: "Hardwork" },
        { src: "acceptness.jpg", text: "Acceptness" },

    ];
    const [selectedImage, setSelectedImage] = useState(inputStore.coreValueInput || "Cooperation");

    const handleImageClick = (image) => {
        setSelectedImage(image.text);
        inputStore.setCoreValueInput(image.text);
    };

    return (
        <div className="flex flex-row justify-center items-center">
            {images.map((image, index) => (
                <div key={index} className="flex flex-col items-center m-2 sm:m-3 md:m-4">
                    <img
                        src={image.src}
                        alt={`Image ${index}`}
                        className={`cursor-pointer rounded-3xl
                         w-12 h-12 sm:w-20 sm:h-20 md:w-16 md:h-16 lg:w-24 lg:h-24`}
                        onClick={() => handleImageClick(image)}
                        style={{
                            boxShadow: selectedImage === image.text ? '0 0 0 3px rgb(59 130 246)' : 'none'
                        }}
                    />
                    <p className="mt-2 text-sm">{image.text}</p>
                </div>
            ))}
        </div>
    );
};

export default CoreValueSelector;