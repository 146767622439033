import { TextField } from "@mui/material";
import { styled } from "@mui/system";

const FormContainer = styled("form")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "20px",
  maxWidth: "80%",
  margin: "0 auto",
});

const CustomTextField2 = styled("input")({
  "& .MuiOutlinedInput-root": {
    variant: "outlined",
    padding: "2px",
    width: "400px",
    borderRadius: "10px",
  },
  variant: "outlined",
  borderRadius: "15px",
  margin: "5px",
  padding: "0px",
  width: "400px",
  fontSize: "14px",
  shrink: true,
});

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    variant: "outlined",
    padding: "2px",
    paddingLeft: "5px",
    width: "full", // Full width by default
    borderRadius: "10px",
    fontFamily: "Kalam",
    color: "black", // Change the color to your desired color
  },
  variant: "outlined",
  "& textarea": {
    minHeight: "100px !important", // Override the global style with !important
  },
  borderRadius: "15px",
  margin: "5px",
  padding: "0px",
  fontSize: "14px",
  // defualt width
  width: "95%",
  "@media (min-width: 640px)": {
    // sm breakpoint
    width: "95%", // Width for small screens (sm breakpoint)
  },
  "@media (min-width: 768px)": {
    // md breakpoint
    width: "60%", // Width for medium screens (md breakpoint)
  },
  "@media (min-width: 1024px)": {
    // lg breakpoint
    width: "50%", // Width for large screens (lg breakpoint)
  },
});
// Styled Wrapper for Text Inputs
const InputWrapper = styled("div")({
  width: "90%",
  margin: "0 auto", // Center the input wrapper horizontally
  display: "flex",
  flexDirection: "column", // Stack inputs vertically
  gap: "10px", // Adjust the gap between inputs
  alignItems: "center",
});

export { FormContainer, CustomTextField, InputWrapper, CustomTextField2 };
