import { useEffect } from "react";
import { FaHourglassStart } from "react-icons/fa";
import PayPalPayment from "../Pricing/PayPalPayment";
import { Link } from "react-scroll";

const Icon = () => {
  return (
    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
      <FaHourglassStart />
    </div>
  );
};

type PaymentModalProps = {
  isOpen: boolean;
  booksAmount: number;
  printsAmount: number;
  onClose: () => void;
  price: number;
};

const PaymentModal = ({
  isOpen,
  onClose,
  price,
  booksAmount,
  printsAmount,
}: PaymentModalProps) => {
  useEffect(() => {
    const handleCloseModal = (event) => {
      if (isOpen && event.target.closest(".modal-content") === null) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleCloseModal);
    }

    return () => {
      document.removeEventListener("click", handleCloseModal);
    };
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-500 bg-opacity-75"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="w-full max-w-lg">
        <div className="flex items-center justify-center p-4 px-6">
          <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex flex-col items-center justify-center">
                <Icon />
                <h3 className="mt-4 text-lg font-semibold leading-6 text-gray-900">
                  Oh, it seems like you need to buy this one.{" "}
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Buy this for {price}$, and immediately start to generate your
                  book.
                </p>
              </div>

              <div className="mt-4">
                <PayPalPayment
                  product_id="create1"
                  booksAmount={booksAmount}
                  printsAmount={printsAmount}
                />
              </div>
              <div className="text-sm text-gray-500 flex items-center justify-center text-center">
                <p className="m-0">Or go to our</p>
                <Link
                  spy={true}
                  active="active"
                  smooth={true}
                  duration={750}
                  key="pricing"
                  to="pricing"
                  href="pricing"
                  onClick={onClose}
                  className="ml-1 mr-1 text-bold"
                >
                  pricing page
                </Link>
                <p className="m-0">to see more options</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
