import { Box, LinearProgress, Step, StepLabel, Stepper } from "@mui/material";
import { useStore } from "src/stores/storeContext";
import BookFailedModal from "./bookFailed";
import { useEffect, useState } from "react";

const Generating = () => {
  const inputStore = useStore().inputStore;
  const [isFailedBook, setIsFailedBook] = useState(false);

  useEffect(() => {
    if (inputStore.generationStatus === 4) {
      setIsFailedBook(true);
    }
  }, [inputStore.generationStatus]);

  const steps = ["Writing plot", "Illustrating", "Generating images"];

  const onCloseFailedBook = () => {
    inputStore.setGenerationStatus(0);
    inputStore.setIsGenerating(false);
    setIsFailedBook(false);
    inputStore.setContentState("stepone");
  };

  if (inputStore.generationStatus === 4) {
    // error
    return (
      <div>
        {isFailedBook && (
          <BookFailedModal isOpen={isFailedBook} onClose={onCloseFailedBook} />
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box style={{ width: "80%" }}>
        <Stepper activeStep={inputStore.generationStatus}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box mt={2} textAlign="center">
          <LinearProgress />
        </Box>
        {/* </Grid> */}
        <Box mt={2} textAlign="center">
          <p>{inputStore.renderGenerationMessage()}</p>
        </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box mt={4} className='flex flex-col items-center justify-center'>
            <h2
              style={{
                fontFamily: "Kalam",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {inputStore.bookNameInput}
            </h2>
            <img
              src={`data:image/jpeg;base64,${inputStore.newBook?.coverImage}`}
              alt="Book Cover"
              className="b-4 w-3/5 h-auto object-fill rounded-lg shadow-lg"
            />
          </Box>
        </div>
      </Box>
    </div>
  );
};

export default Generating;
