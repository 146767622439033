import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "src/stores/storeContext";
import { PayPalButtons } from "@paypal/react-paypal-js";

type PdfPaymentProps = {
  product_id: string;
  bookId: string;
  bookName: string;
  setModalOpen?: (data: boolean) => void;
  setIsPaymentSucceeded?: (data: boolean) => void;
  onClose: () => void;
};

const PdfPayment = ({
  product_id,
  bookId,
  bookName,
  onClose,
  setModalOpen = (data) => {
    console.log(data);
  },
  setIsPaymentSucceeded = (data) => {
    console.log(data);
  },
}: PdfPaymentProps) => {
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently } =
    useAuth0();
  const { userStore } = useStore();

  async function createOrder() {
    const token = await getAccessTokenSilently();
    return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/orders/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify({
        product: {
          id: product_id,
        },
      }),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  }

  async function onApprove(data) {
    const token = await getAccessTokenSilently();
    // uupdate the server that the user bought the book
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/book/${bookId}/pdf`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.ok) {
      console.log("is book Pdf bought updated successfully");
    } else {
      console.error("Failed to update permissions:", await response.text());
    }
    // fetch the server to capture the payment
    return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/orders/capture`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: data.orderID,
        product_id: product_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + "");
        }
        return response.json();
      })
      .then(() => {
        setIsPaymentSucceeded(true);
        setModalOpen(true); // Open the modal
        //Download the payed pdf (after the payment approved)
        // Fetch the PDF content
        let pdfUrl = `https://d2h7guss2h2lht.cloudfront.net/${bookId}/${bookName.replaceAll(
          " ",
          "_"
        )}.pdf`;
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.target = "_blank"; // Open the link in a new tab
        link.rel = "noopener noreferrer"; // Security best practice for opening links in a new tab
        link.download = `${bookName}.pdf`; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        onClose();
      })
      .catch(() => {
        setIsPaymentSucceeded(false);
        setModalOpen(true); // Open the modal
      });
  }

  const authenticateUser = async () => {
    if (!isAuthenticated) {
      try {
        await loginWithPopup();
        let token = await getAccessTokenSilently();
        await userStore.setUser(token);
      } catch (e) {
        return;
      }
    }
  };

  return isAuthenticated ? (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      style={{ layout: "horizontal", tagline: false }}
    />
  ) : (
    <button
      onClick={authenticateUser}
      className="bg-blue-500 hover:bg-blue-700 text-md text-white py-1 px-2 rounded"
    >
      login to buy
    </button>
  );
};

export default PdfPayment;
