import { useAuth0 } from "@auth0/auth0-react";
import { useStore } from "src/stores/storeContext";
import { PayPalButtons } from "@paypal/react-paypal-js";

type PayPalProps = {
  product_id: string;
  booksAmount: number;
  printsAmount: number;
  setModalOpen?: (data: boolean) => void;
  setIsPaymentSucceeded?: (data: boolean) => void;
};

const PayPalPayment = ({
  product_id,
  booksAmount,
  printsAmount,
  setModalOpen = (data) => {
    console.log(data);
  },
  setIsPaymentSucceeded = (data) => {
    console.log(data);
  },
}: PayPalProps) => {
  const { isAuthenticated, loginWithPopup, getAccessTokenSilently } =
    useAuth0();
  const { userStore } = useStore();

  async function createOrder() {
    if (!isAuthenticated) {
      await loginWithPopup();
      const token = await getAccessTokenSilently();
      userStore.setUser(token);
    }

    const token = await getAccessTokenSilently();
    return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/orders/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // use the "body" param to optionally pass additional order information
      // like product ids and quantities
      body: JSON.stringify({
        product: {
          id: product_id,
        },
      }),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  }

  async function onApprove(data) {
    if (!isAuthenticated) {
      await loginWithPopup();
      const token = await getAccessTokenSilently();
      userStore.setUser(token);
    }

    const token = await getAccessTokenSilently();
    return fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/orders/capture`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_id: data.orderID,
        product_id: product_id,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status + "");
        }
        return response.json();
      })
      .then(() => {
        setIsPaymentSucceeded(true);
        userStore.updateBooksQuota(booksAmount);
        userStore.updatePrintsQuota(printsAmount);
        setModalOpen(true); // Open the modal
      })
      .catch(() => {
        setIsPaymentSucceeded(false);
        setModalOpen(true); // Open the modal
      });
  }
  return (
    <PayPalButtons
      createOrder={createOrder}
      onApprove={onApprove}
      style={{ layout: "horizontal", tagline: false }}
    />
  );
};

export default PayPalPayment;
