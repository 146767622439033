import { Book } from "../../stores/booksStore";
import { useNavigate } from "react-router-dom";
import { Card, CardActionArea, CardMedia, Typography } from "@mui/material";
import { CardHeader } from "reactstrap";

const cdnAddress = "https://images.kidstorygen.com";

interface BookCoverViewerProps {
  book: Book;
}

const BookCoverViewer: React.FC<BookCoverViewerProps> = ({ book }) => {
  const navigate = useNavigate();

  const handleBookClick = async (book: Book) => {
    navigate(`/book/${book.id}`);
  };

  //const boxShadow = "rgba(0, 0, 0, 0.25) 5px 5px, rgba(0, 0, 0, 0.2) 8px 8px, rgba(0, 0, 0, 0.15) 10px 10px, rgba(0, 0, 0, 0.1) 12px 12px, rgba(0, 0, 0, 0.05) 15px 15px";

  return (
    <Card
      sx={{
        transition: "transform 0.3s ease-in-out", // Apply transition effect
        borderRadius: "0.75rem",
        //boxShadow: boxShadow,
        border: "2px solid rgba(0, 0, 0, 0.3)",
        "&:hover": {
          transform: "scale(1.05)", // Enlarge the card on hover
        },
      }}
    >
      <CardActionArea onClick={() => handleBookClick(book)}>
        <CardHeader className="bg-blue-100">
          <Typography
            component="div"
            textAlign="center"
            style={{
              fontFamily: "Kalam",
              fontWeight: "bold",
              fontSize: "1.2rem",
              padding: "6px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {book.name}
          </Typography>
        </CardHeader>

        <CardMedia
          component="img"
          // image={`data:image/jpeg;base64,${book.coverImage}`}
          image={`${cdnAddress}/${book.id}/cover.jpg`}
          alt={`First page of ${book.name}`}
          // Specify fixed height and width for uniformity
          sx={{
            height: "90%", // Set the desired height for the book cover
            width: "100%", // Set the width to take up the entire space
            objectFit: "cover", // Maintain aspect ratio and cover entire area
          }}
        />
      </CardActionArea>
    </Card>
  );
};

export default BookCoverViewer;
