import * as React from "react";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#eb2bfc" : "#f492fc",
    boxSizing: "border-box",
  },
}));

const Gender = ({ isBoy, setIsBoy }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsBoy(event.target.checked);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography>Girl</Typography>
      <AntSwitch
        inputProps={{ "aria-label": "ant design" }}
        checked={isBoy}
        onChange={handleChange}
      />
      <Typography>Boy</Typography>
    </Stack>
  );
};

export default Gender;
