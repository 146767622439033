import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { useStore } from "src/stores/storeContext";
import { FaBars, FaTimes } from "react-icons/fa"; // Import icons from react-icons

const Header = () => {
  const userStore = useStore().userStore;
  const {
    user,
    isAuthenticated,
    loginWithPopup,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const handleLogin = async () => {
    await loginWithPopup();
    try {
      const token = await getAccessTokenSilently();
      userStore.setUser(token);
    } catch (err) { }
  };

  const company = {
    name: "AI kid's book generator",
    logo: "/kid.png",
  };

  const navigation_logedin = [
    {
      name: "Start Now",
      href: "generate",
    },
    {
      name: "My Books",
      href: "mybooks",
    },
    {
      name: "Examples",
      href: "examples",
    },

    {
      name: "Pricing",
      href: "pricing",
    },
  ];

  const navigation_logedout = [
    {
      name: "Start Now",
      href: "generate",
    },
    {
      name: "Examples",
      href: "examples",
    },
    {
      name: "Pricing",
      href: "pricing",
    },
  ];

  const profile_menu = [
    `You have ${userStore.booksQuota} books left to create`,
    `You have ${userStore.printsQuota} books left to print`,
  ];

  useEffect(() => {
    const updateQuotas = async () => {
      try {
        const token = await getAccessTokenSilently();
        await userStore.getUser(token);
      } catch (err) { }
    };
    updateQuotas();
  }, [userStore.booksQuota, userStore.printsQuota]);

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollToSection = (href) => {
    navigate(`/#${href}`);
    scroller.scrollTo(href, {
      duration: 750,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className="min-h-full">
      <nav className="bg-[#fffff9]">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              {/* Logo  */}
              <div className="flex-shrink-0 cursor-pointer">
                <a onClick={() => handleScrollToSection("")}>
                  <img
                    className="h-8 md:h-10"
                    src={company.logo}
                    alt={company.name}
                  />
                </a>
              </div>
              {/* Menu button- md breakpoint  */}
              <div className="hidden md:block">
                {isAuthenticated ? (
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation_logedin.map((item) => (
                      <div key={item.name}>
                        {location.pathname !== "/" ? (
                          // If not on the home route ("/"), navigate to home ("/") and scroll to href
                          <a
                            key={item.name}
                            onClick={(e) => {
                              e.preventDefault();
                              handleScrollToSection(item.href);
                            }}
                            className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            spy={true}
                            active="active"
                            smooth={true}
                            duration={750}
                            key={item.name}
                            to={item.href}
                            href={item.href}
                            className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ml-10 flex items-baseline space-x-4">
                    {navigation_logedout.map((item) => (
                      <div key={item.name}>
                        {location.pathname !== "/" ? (
                          // If not on the home route ("/"), navigate to home ("/") and scroll to href
                          <a
                            key={item.name}
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              handleScrollToSection(item.href);
                            }}
                            className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                          >
                            {item.name}
                          </a>
                        ) : (
                          <Link
                            spy={true}
                            active="active"
                            smooth={true}
                            duration={750}
                            key={item.name}
                            to={item.href}
                            href={item.href}
                            className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                          >
                            {item.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            {/* Login button - md breakpoint */}
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="relative ml-3">
                  <div>
                    {isAuthenticated ? (
                      <button
                        type="button"
                        className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        id="user-menu-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
                      >
                        <span className="absolute -inset-1.5"></span>
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={user?.picture}
                          alt=""
                        />
                      </button>
                    ) : (
                      <button
                        className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"
                        type="button"
                        id="login-button"
                        aria-expanded="false"
                        aria-haspopup="true"
                        onClick={handleLogin}
                      >
                        Login
                      </button>
                    )}
                  </div>
                  {/* Profile dropdown */}
                  {isProfileMenuOpen && (
                    <div
                      className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                    >
                      {profile_menu.map((item) => (
                        <Link
                          spy={true}
                          active="active"
                          smooth={true}
                          duration={750}
                          key={item}
                          to="pricing"
                          href="pricing"
                          className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                        >
                          {item}
                        </Link>
                      ))}
                      <a
                        className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                        role="menuitem"
                        href="#"
                        onClick={() =>
                          logout({
                            logoutParams: { returnTo: window.location.origin },
                          })
                        }
                      >
                        Sign out
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Hamburger menu - sm breakpoint */}
            <div className="-mr-2 flex md:hidden">
              <button
                type="button"
                className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 focus:outline-none"
                aria-controls="mobile-menu"
                aria-expanded="false"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <span className="absolute -inset-0.5"></span>
                <span className="sr-only">Open main menu</span>
                {isMobileMenuOpen ? (
                  <FaTimes className="h-6 w-6 text-black" />
                ) : (
                  <FaBars className="h-6 w-6 text-black" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu, show/hide based on menu state. */}
        {isMobileMenuOpen && (
          <div className="md:hidden" id="mobile-menu">
            {isAuthenticated ? (
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation_logedin.map((item) => (
                  <div key={item.name}>
                    {location.pathname !== "/" ? (
                      // If not on the home route ("/"), navigate to home ("/") and scroll to href
                      <a
                        key={item.name}
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          handleScrollToSection(item.href);
                        }}
                        className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        spy={true}
                        active="active"
                        smooth={true}
                        duration={750}
                        key={item.name}
                        to={item.href}
                        href={item.href}
                        className="text-black hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium cursor-pointer"
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                <button
                  className="text-black hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  type="button"
                  id="login-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                  onClick={handleLogin}
                >
                  Login
                </button>
                {navigation_logedout.map((item) => (
                  <div key={item.name}>
                    {location.pathname !== "/" ? (
                      // If not on the home route ("/"), navigate to home ("/") and scroll to href
                      <a
                        key={item.name}
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          handleScrollToSection(item.href);
                        }}
                        className="text-black hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium cursor-pointer"
                      >
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        spy={true}
                        active="active"
                        smooth={true}
                        duration={750}
                        key={item.name}
                        to={item.href}
                        href={item.href}
                        className="text-black hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium cursor-pointer"
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            )}

            {isAuthenticated && (
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user?.picture}
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="pb-1 text-base font-medium leading-none">
                      {user?.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {user?.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {profile_menu.map((item) => (
                    <Link
                      spy={true}
                      active="active"
                      smooth={true}
                      duration={750}
                      key={item}
                      to="pricing"
                      href="pricing"
                      className="text-black hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium cursor-pointer"
                    >
                      {item}
                    </Link>
                  ))}
                  <a
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                    href="#"
                    className="text-black hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </nav>
    </div>
  );
};

export default Header;
