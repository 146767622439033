const LoadingGif = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "5px",
      }}
    >
      <img src="/writing.gif" alt="Loading..." />
    </div>
  );
};

export default LoadingGif;
