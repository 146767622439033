import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { Book, BookClass } from "./booksStore";

export type ContentState = "stepone" | "steptwo" | "loading" | "generating";
type GenerationStatus = 0 | 1 | 2 | 3 | 4;
const GENERATE_API = `${process.env.REACT_APP_SERVER_ADDRESS}/v2/generate`;
const BOOK_TEXT_API = `${process.env.REACT_APP_SERVER_ADDRESS}/book/BOOKID`;
const GENERATE_STATUS_API = `${process.env.REACT_APP_SERVER_ADDRESS}/v2/generate/status/BOOKID`;

class InputStore {
  nameInput: string = "";
  ageInput: string = "";
  parentsNameInput: string = "";
  kidDetailsInput: string | undefined = undefined;
  plotInput: string | undefined = undefined;
  kidAppearanceInput: string =
    "a boy with a brown hair, blue eyes, and fair skin";
  bookNameInput: string | undefined = undefined;
  coreValueInput: string = 'Cooperation';
  contentState: ContentState = "stepone";
  generationStatus: GenerationStatus = 0;

  isLoading: boolean = false;
  isGenerating: boolean = false;
  isFetchingGenerationStatus: boolean = false;

  newBook: Book | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  async onSubmit(token: any) {
    this.setIsLoading(true); // start loading

    // replace a with a 7n yesars old kid in kidAppearance
    let kidAppearance = this.kidAppearanceInput.replace(
      /a\s/,
      `${this.ageInput}-year-old `
    );
    try {
      // Send "api/v2/generated" POST request to the server - start creation
      const response = await fetch(GENERATE_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          name: this.nameInput,
          parentsName: this.parentsNameInput,
          age: this.ageInput,
          kidDetails: this.kidDetailsInput + ' ' + this.plotInput,
          kidAppearance: kidAppearance,
          bookName: this.bookNameInput,
          coreValue: this.coreValueInput,
        }),
      });

      const data = await response.json();
      if (response.status !== 200) {
        throw (
          data.error ||
          new Error(`Request failed with status ${response.status}`)
        );
      }
      const { bookId, bookCover, user_email } = data;

      let newBook: Book = new BookClass(bookId);
      if (this.bookNameInput) newBook.name = this.bookNameInput;
      newBook.coverImage = bookCover;
      newBook.user_email = user_email;
      this.setNewBook(newBook);
      this.setIsLoading(false);
      this.setIsGenerating(true);
      return true;
    } catch (error) {
      // Consider implementing your own error handling logic here
      Sentry.captureException(error);
      this.setIsLoading(false); // end loading
      return false;
    }
  }

  // get from server the generation status (1/2/3)
  public fetchGenerationStatus = async (token: any) => {
    if (this.isFetchingGenerationStatus) return; // Prevent making a new request if the previous one is still in progress.
    if (!this.newBook) return;

    this.setIsFetchingGenerationStatus(true); // Start the request

    try {
      const apiUrl = GENERATE_STATUS_API.replace("BOOKID", this.newBook.id);
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }
      const data = await response.json();

      //after getting status, get the current status information from server
      await this.handleGenerationStatus(data, token);
    } catch (error) {
      // Handle the error accordingly
      Sentry.captureException(error);
    } finally {
      this.setIsFetchingGenerationStatus(false); // End the request
    }
  };

  public handleGenerationStatus = async (res: any, token: any) => {
    if (Number(res.status) >= 0 && Number(res.status) <= 4) {
      this.setGenerationStatus(Number(res.status) as GenerationStatus);
    } else {
      console.error("Status is not within the valid range.");
    }
    if (!this.newBook) return;

    // gets the pages and visual discriptions from the server and updates this.newBook.
    if (res.status === "2") {
      const bookApi = BOOK_TEXT_API.replace("BOOKID", this.newBook.id);
      const response = await fetch(bookApi, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      });
      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const bookTextData = await response.json();
      const pagesText = bookTextData.pages;
      const visualDescription = bookTextData.visualDescription;

      this.newBook!.pages = pagesText;
      this.newBook!.visualDescriptions = visualDescription;
    }

    // gets book cover and images from the server and updates this.newBook.
    // also, set the current selected book as the new book and and the new book to books[] list.
    if (res.status === "3") {
      this.setIsGenerating(false);
    }
  };

  public renderGenerationMessage = () => {
    switch (this.generationStatus) {
      case 0:
        return "Plot takes about 10-15 seconds...";
      case 1:
        return "Visual descriptions takes about 15-20 seconds...";
      case 2:
        return "Generating images takes about 25 seconds...";
      default:
        return "Your AI book is ready! Soon it will be yours.";
    }
  };

  public clearInputs = () => {
    this.nameInput = "";
    this.ageInput = "";
    this.parentsNameInput = "";
    this.kidDetailsInput = undefined;
    this.plotInput = undefined;
    this.kidAppearanceInput =
      "a boy with a brown hair, blue eyes, and fair skin";
    this.bookNameInput = undefined;
    this.coreValueInput = 'Cooperation';
  };

  setNameInput = async (value: string) => {
    runInAction(() => {
      this.nameInput = value;
    });
  };

  setAgeInput = async (value: string) => {
    runInAction(() => {
      this.ageInput = value;
    });
  };

  setParentsNameInput = async (value: string) => {
    runInAction(() => {
      this.parentsNameInput = value;
    });
  };

  setKidDetailsInput = async (value: string | undefined) => {
    runInAction(() => {
      this.kidDetailsInput = value;
    });
  };

  setPlotInput = async (value: string | undefined) => {
    runInAction(() => {
      this.plotInput = value;
    });
  }

  setKidAppearanceInput = async (value: string) => {
    runInAction(() => {
      this.kidAppearanceInput = value;
    });
  };

  setBookNameInput = async (value: string | undefined) => {
    runInAction(() => {
      this.bookNameInput = value;
    });
  };

  setCoreValueInput = async (value: string) => {
    runInAction(() => {
      this.coreValueInput = value;
    });
  }

  setContentState = async (state: ContentState) => {
    runInAction(() => {
      this.contentState = state;
    });
  };

  setGenerationStatus = async (status: GenerationStatus) => {
    runInAction(() => {
      this.generationStatus = status;
    });
  };

  setIsLoading = async (status: boolean) => {
    runInAction(() => {
      this.isLoading = status;
    });
  };

  setIsGenerating = async (status: boolean) => {
    runInAction(() => {
      this.isGenerating = status;
    });
  };

  setIsFetchingGenerationStatus = async (status: boolean) => {
    runInAction(() => {
      this.isFetchingGenerationStatus = status;
    });
  };

  setNewBook = async (book: Book | undefined) => {
    runInAction(() => {
      this.newBook = book;
    });
  };
}

export default InputStore;
